import pdfFileIcon from '@/assets/images/file-icons/icon-pdf.png'
import docxFileIcon from '@/assets/images/file-icons/icon-docx.png'
import docFileIcon from '@/assets/images/file-icons/icon-doc.png'
import xlsxFileIcon from '@/assets/images/file-icons/icon-xlsx.png'
import xlsFileIcon from '@/assets/images/file-icons/icon-xls.png'
import csvFileIcon from '@/assets/images/file-icons/icon-csv.png'
import odtFileIcon from '@/assets/images/file-icons/icon-odt.png'

export default {

    computed: {
        pdf () { return pdfFileIcon },
        docx () { return docxFileIcon },
        doc () { return docFileIcon },
        xlsx () { return xlsxFileIcon },
        xls () { return xlsFileIcon },
        csv () { return csvFileIcon },
        odt () { return odtFileIcon },
    },
}
