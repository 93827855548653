/* eslint no-unused-vars: "off" */
import ApiService from '@/services/api'

export default {
    namespaced: true,

    state: {
        client: {},
        clients: []
    },

    mutations: {
        SET_CLIENTS (state, payload) {
            state.clients = payload
        },

        SET_CLIENT (state, payload) {
            state.client = payload
        },
    },

    actions: {
        async getClients ({ commit }) {
            const response = await ApiService.get('api/admin/clients/list')
                .catch(error => console.log(error))

            commit('SET_CLIENTS', response.data.clients)
        },

        async getClient ({ commit }, uuid) {
            const response = await ApiService.get(`api/admin/clients/${uuid}/show`)
                .catch(error => console.log(error))

            commit('SET_CLIENT', response.data)
        }
    },

    getters: {
        getClient: state => state.client,
        getClients: state => state.clients
    }
}
