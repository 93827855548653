<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-home"></i>
                            Tableau de bord
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20" v-show="!isLoading">
            <div class="row">
                <div class="col-sm-4">
                    <div class="dashboard-tile white">
                        <div class="number">{{ dashboard.projects }}</div>
                        <div class="title">Projets</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="dashboard-tile red">
                        <div class="number">{{ dashboard.clients }}</div>
                        <div class="title">Clients</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="dashboard-tile green">
                        <div class="number">{{ dashboard.corpus }}</div>
                        <div class="title">Corpus</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="dashboard-tile dark">
                        <div class="number">{{ dashboard.users }}</div>
                        <div class="title">Utilisateurs</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="dashboard-tile blue">
                        <div class="number">{{ dashboard.keywords }}</div>
                        <div class="title">Moyenne keywords par corpus</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="dashboard-tile primary">
                        <div class="number">{{ dashboard.verbatims }}</div>
                        <div class="title">Moyenne verbatim par corpus</div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <div></div>
    </div>
</template>

<script>
export default {
    name: 'Dashboard',

    data: () => ({
        dashboard: [],
    }),

    mounted () {
        this.loadDashboard()
    },

    methods: {
        /**
        * Load dashboard
        *
        * @return {void}
        */
        async loadDashboard () {
            this.isLoading = true

            const response = await this.$api.get('api/admin/dashboard')
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.isLoading = false
                this.dashboard = response.data
            }
        },
    }
}
</script>
