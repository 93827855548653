<template>
    <div class="parent-box">
        <div class="tags-container" @click="showDropdown">
            <span class="pointer" v-if="names.length == 0">
                Sélectionner les corpus
            </span>
            <div class="tag" v-for="n in names" :key="n" v-else>
                {{ n }} <!-- <i class="feather icon-x" @click="removeCorpus(n)"></i> -->
            </div>
        </div>
        <div class="multiselect-checkbox elevated" v-if="show">
            <ul>
                <li class="form">
                    <input
                        type="text"
                        v-model="ghost.query"
                        id="query"
                        class="form-control form-control-lg mb-10"
                    >
                </li>
                <li v-for="(option, index) in display" :key="index" class="item">
                    <input class="mr-2" type="checkbox" :id="index" :value="option" v-model="selected">
                    <label :for="index">{{ option.name }}</label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'MultiselectCheckbox',
    emits: ['checked'],

    props: {
        options: { type: Array, default: ()=> [] },
    },

    data: () => ({
        show: false,
        selected: [],
        display: [],
    }),

    computed: {
        names () {
            return this.$store.state.projects.names
        }
    },

    mounted () {
        this.$eventBus.$on('addProject', () => {
            this.selected = []
            this.show = false
            this.ghost.query = ''
        })
    },

    watch: {
        selected: {
            immediate: true,
            handler: function (val) {
                this.$store.commit('projects/SET_NAMES', val.map(v=>v.name))
                this.$emit('checked', val)
            }
        },

        options: {
            immediate: true,
            handler: function (val) {
                this.display = _.cloneDeep(val)
            }
        },

        'ghost.query': {
            handler: function (val) {
                this.display = this.options.filter(o => {
                    return o.name.toLowerCase().includes(val.toLowerCase())
                })
            }
        }
    },

    methods: {
        showDropdown () {
            this.show = !this.show
            if (this.show) {
                setTimeout(() => {
                    window.$('#query').focus()
                }, 0)
            }
        },

        removeCorpus (n) {
            this.names = this.names.filter(f=> f !== n)
        }
    }
}

</script>
