import 'es6-promise/auto'
import { createStore } from 'vuex'
import rolesModule from './modules/roles'
import clientsModule from './modules/clients'
import projectsModule from './modules/projects'
import corpusModule from './modules/corpus'

const store = {
    state: () => ({
        user: {},
        message: '',
        alert: '',
        showModal: false,
        processing: false,
    }),

    mutations: {
        SET_USER (state, payload) {
            state.user = payload
        },

        SET_MESSAGE (state, sms) {
            state.message = sms
        },

        SET_ALERT (state, alert) {
            state.alert = alert
        },

        SET_SHOW_MODAL (state, value) {
            state.showModal = value
        },

        SET_PROCESSING (state, value) {
            state.processing = value
        },
    },

    modules: {
        roles: rolesModule,
        clients: clientsModule,
        projects: projectsModule,
        corpus: corpusModule,
    }
}

export default createStore(store)
