<template>
    <div id="project-wrapper" class="toggled">
        <Sidebar />

        <div id="page-content-wrapper" v-show="!isLoading">
            <div class="page-content-header">
                <h4>{{ name }}</h4>
            </div>

            <div class="container-fluid">
                <div class="page-content">
                    <div class="verbatims mt-40">

                        <div class="mt-20">
                            <div class="filtre">
                                <div class="header">
                                    <h3>Filtres:</h3>
                                </div>
                            </div>

                            <div class="result float-right">
                                {{ total_results }} Verbatims trouvés
                            </div>


                            <form class="_form" @submit.prevent="loadFilters()">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group mt-10">
                                            <label for="">Appartient au corpus</label>
                                            <VueMultiselect
                                                v-model="value_corpus"
                                                label="name"
                                                track-by="uuid"
                                                placeholder="Sélectionner les corpus"
                                                :options="p_corpus"
                                                :multiple="true"
                                                :taggable="true"
                                                @tag="addTag"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group mt-10">
                                            <label for="">Contient étiquette(s)</label>
                                            <VueMultiselect
                                                v-model="value"
                                                :options="options"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                :multiple="true"
                                                placeholder="Sélectionner les étiquettes"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group mt-10">
                                            <label for="">Contient l'expression</label>
                                            <input type="text"
                                                name="name"
                                                placeholder="Entrer l’expression"
                                                class="form-control input-white haut"
                                                v-model="ghost.query"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-sm-8">

                                    </div>

                                    <div class="col-sm-4 text-right">
                                        <button
                                            class="btn btn-primary elevated mr-2"
                                            type="submit"
                                        >
                                            Filtrer
                                        </button>
                                        <button
                                            class="btn btn-secondary elevated"
                                            type="button"
                                            @click.prevent="exportVerbatims()"
                                        >
                                            Exporter
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <div class="row mt-10">
                                <div class="col-sm-7">
                                    <form @submit.prevent="applySelected()">
                                        <div class="row">
                                            <div class="col-3">
                                                <IzyApplyCheckbox
                                                    :types="'all'"
                                                    :clicked="isClicked"
                                                    @allselected="allselected"
                                                    :text="'Tout sélectionner'"
                                                ></IzyApplyCheckbox>
                                            </div>

                                            <div class="col-9">
                                                <div class="form-group">
                                                    <label for="">Ajouter une étiquette à la sélection</label>
                                                    <div class="form-inline">
                                                        <input type="text"
                                                            name="tag"
                                                            placeholder=""
                                                            class="form-control input-white mr-2"
                                                            v-model="taghost.tag"
                                                            maxlength="30"
                                                        >
                                                        <button
                                                            class="btn btn-primary"
                                                            type="submit"
                                                            :disabled="isDisabled"
                                                        >
                                                            Appliquer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-12">
                                    <div class="bando" v-if="checkValues">
                                        <div v-if="showFirst">
                                            <span>Les {{ selected.length }} verbatims de cette page sont selectionnés.  </span>
                                            <span class="span pointer bold" @click="apply('total')">Sélectionner les {{ total_results }} verbatims du filtre.</span>
                                        </div>

                                        <div v-else>
                                            <span>Les {{ total_results }} verbatims du filtre sont sélectionnés.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="_overflow-table">
                                <table class="table table-striped table-verbatims">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col" class="td-5 text-left">Sélection</th>
                                            <th scope="col" class="td-5 text-left pointer" @click="setSortingKey('title')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.title"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.title"></i>
                                                Titre</th>
                                            <th scope="col" class="td-40 text-left">
                                                Verbatim</th>
                                            <th scope="col" class="td-5 text-left">Etiquettes</th>
                                            <th scope="col" class="td-5 text-left pointer" @click="setSortingKey('corpus')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.corpus"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.corpus"></i>
                                                Corpus</th>
                                            <th scope="col" class="td-5 text-left pointer" @click="setSortingKey('url')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.url"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.url"></i>
                                                Urls</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="v in verbatims" :key="v.uuid">
                                            <td class="td-5">
                                                <IzyApplyCheckbox
                                                    id="meta_del_btn"
                                                    :value="v.uuid"
                                                    :selected="selected"
                                                    :text="''"
                                                    @changed="VerbatimsChanged"
                                                >
                                                </IzyApplyCheckbox>
                                            </td>
                                            <td class="td-5">{{ truncateTitle(v.title) }}</td>
                                            <td class="verbatim td-40" @click="voirTagModal(v.text)">{{ truncateText(v.text) }}</td>
                                            <td class="tags">
                                                <i class="feather icon-plus" @click="selectTagModal(v)"></i>
                                                <ul class="list-inline" v-if="v.tags.length > 0">
                                                    <li
                                                        v-for="t in v.tags"
                                                        class="list-inline-item"
                                                        @click="remove(t,v)"
                                                        :key="t.uuid"
                                                        :id="`tag-${t.uuid}`"
                                                    >{{ t.tag }}</li>
                                                </ul>
                                            </td>
                                            <td class="td-5">{{ truncateCorpus(v.corpus.name) }}</td>
                                            <td class="td-5">
                                                <span v-if="v.type==1">{{ v.domain }}</span>
                                                <a :href="v.url" target="_blank" v-else>{{ v.domain }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mt-20 text-center">
                                <izyPaginate
                                    @nextPage="loadFilters"
                                    @previousPage="loadFilters"
                                    @currentPage="loadFilters"
                                    :showNextBtn="showNextBtn"
                                    :page="page"
                                ></izyPaginate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <VoirTextModal :text="selectedTagText"></VoirTextModal>
        <AddEtiquetteModal
            @confirmed="saveTags"
            :verbatim="selectedVerbatim"
            :page="page"
        ></AddEtiquetteModal>

        <SelectEtiquetteModal
            @confirmed="saveTags"
            @add="addTagModal"
            :tagsList="project_infos.tags"
            :verbatim="selectedVerbatim"
            :page="page"
        ></SelectEtiquetteModal>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selectedTag"
            :page="page"
            @confirmed="confirmRemoveEtiquette" />
    </div>
</template>

<script>
import SelectEtiquetteModal from './modals/select-etiquette-modal'
import AddEtiquetteModal from './modals/add-etiquette-modal'
import VoirTextModal from './modals/voir-text-modal'
import VueMultiselect from 'vue-multiselect'

export default {
    name: 'Verbatims',

    components: {
        VueMultiselect,
        SelectEtiquetteModal,
        AddEtiquetteModal,
        VoirTextModal
    },

    data: () => ({
        page: 1,
        showNextBtn: false,
        isClicked: false,
        showFirst: true,
        displayBandeau: false,
        show_x_verbatims: true,
        verbatims: [],
        verbatimTags: [],
        selected: [],
        selectedCopy: [],
        total_results: 0,
        size: 30,
        selectedTagText: '',
        type: 'tags',
        msg: '',
        taghost: {
            tag: '',
            verbatims: []
        },
        selectedTag: {},
        selectedVerbatim: {},
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer l\'étiquette ?',

        // Is this asc ?
        sort_items:{
            'title': true,
            'text': true,
            'corpus': true,
            'url': true,
        },

        // variavles de multiselect
        value: null,
        value_corpus: [],
    }),

    created () {
        this.loadProjectData()
        this.loadFilters()
        this.initFilter()
    },

    computed: {
        p_corpus () {
            return this.$store.state.projects.project_corpus || []
        },

        project_infos () {
            return this.$store.state.projects.project_infos || []
        },

        options () {
            return this.project_infos ? this.project_infos.tags : []
        },

        project () {
            return this.$store.state.projects.project || {}
        },

        name () {
            return this.project ? this.project.name : ''
        },

        param () {
            return this.$router.currentRoute._value.params.id
        },

        checkValues () {
            return this.size == this.selected.length
        },

        canDisplay () {
            return this.displayBandeau && this.checkValues
        },

        isDisabled () {
            return this.selected.length < 2
        }
    },

    methods: {
        apply (val) {
            this.type = val
            if (this.type == 'tags') {
                this.$toast.success(`Les ${this.selected.length} verbatims de cette page ont été sélectionnés.`)
            } else {
                this.showFirst = false
                this.$toast.success(`Les ${this.total_results} verbatims du filtre sont sélectionnés.`)
            }
        },

        applySelected () {
            if (this.type == 'tags') {
                if (this.taghost.tag == '') {
                    this.$swal.error('Validation', 'Saisir la valeur de l\'étiquette à ajouter à la sélection')
                } else {
                    this.saveApply()
                }
            }

            if (this.type == 'total') {
                this.selectYVerbatims()
            }
        },

        addTag (newTag) {
            const tag = {
                uuid: newTag.uuid,
                name: newTag.name
            }

            // this.project_corpus.push(tag)
            this.value_corpus.push(tag)
        },

        /**
        * Object of params
        *
        * @return {Params}
        */
        setParams (page=1) {
            let tag = this.value

            let payload = {
                project: this.param,
                query: this.ghost.query,
                corpus: this.value_corpus.map(c => c.uuid),
                tag: tag,
                page: page,
                sort_on: this.ghost.sort_on,
                sort_type: this.ghost.sort_type,
                new_tag: this.taghost.tag,
                uuids: this.ghost.uuids,
            }

            // Retirer un param quand il est null ou pas defini
            if (payload.corpus == undefined || payload.corpus == ''|| payload.corpus == null) delete payload.corpus
            if (this.value == undefined || this.value == null || this.value.length == 0) delete payload.tag
            if (payload.pos == undefined || payload.pos == '') delete payload.pos
            if (payload.query == undefined || payload.query == '') delete payload.query
            if (payload.sort_on == undefined || payload.sort_on == '') delete payload.sort_on
            if (payload.sort_type == undefined || payload.sort_type == '') delete payload.sort_type
            if (this.taghost.tag == undefined || this.taghost.tag == '') delete payload.new_tag
            if (payload.page == undefined || payload.page == null) delete payload.page
            if (payload.uuids == undefined || payload.uuids == null) delete payload.uuids

            return payload
        },

        /**
        * Sélectionner les Y verbatims du filtre
        *
        * @return {void}
        */
        async selectYVerbatims () {
            this.isLoading = true

            let postData = this.setParams()

            const res = await this.$api.post('api/verbatims/filter/add/tag', postData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.loadFilters(this.page)
                this.loadProjectData()
                this.resetApplyHost()

                this.$swal.error('Confirmation', res.data)
            }
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            let page = JSON.parse(localStorage.getItem('current_page'))
            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadFilters(page)
        },

        async saveApply () {
            this.isLoading = true

            this.taghost.verbatims = this.selectedCopy.map(f => f.uuid)
            let postData = {
                "project": this.param,
                "verbatims": this.taghost.verbatims,
                "tag": this.taghost.tag,
            }

            const res = await this.$api.post('api/verbatims/tag/add', postData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                    this.taghost.tag = ""
                })

            if (res) {
                this.isLoading = false
                this.loadFilters(this.page)
                this.loadProjectData()
                this.resetApplyHost()

                this.$swal.error('Confirmation', res.data)
            }
        },

        resetApplyHost () {
            this.taghost = {
                tag: ''
            }

            this.show_x_verbatims = false
            this.isClicked = false
            this.type = 'tags'
            this.showFirst = true
            this.selectedCopy = []
            this.selected = []
        },

        VerbatimsChanged (payload) {
             if (payload.action == 'add') {
                 let temp = this.verbatims.filter(f => f.uuid === payload.value)[0]
                 this.selectedCopy.push(temp)
                 return this.selected.push(payload.value)
             }

             this.selectedCopy = this.selectedCopy.filter(act => act.uuid !== payload.value)
             this.selected = this.selected.filter(act => act !== payload.value)
         },

        allselected (payload) {
             if (payload.action == 'add') {
                 this.isClicked = true
                 this.selectedCopy = this.verbatims
                 return this.selected = this.selectedCopy.map(f => f.uuid)
             }

             if (payload.action == 'remove') {
                 this.isClicked = false
                 this.displayBandeau = false
                 this.selectedCopy = []
                 this.selected = []
                 this.type = 'tags'
                 this.showFirst = true
             }
         },

        async exportVerbatims() {
            this.isLoading = true

            let payload = this.setParams()

            const create_export_res = await this.$api.post('api/verbatims/create/export', payload )
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (create_export_res) {
                let get_payload = {
                    project: this.param,
                    temp_file: create_export_res.data.temp_file
                }
                const res = await this.$api.get('api/verbatims/export', {params:get_payload, responseType:'blob'} )
                    .catch(error => {
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur', str)
                    })
                this.isLoading = false

                if (res) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "verbatims_" + this.project.name + ".xlsx");
                    document.body.appendChild(link);
                    link.click();
                }
            }
        },


        async loadFilters (page) {
            this.isLoading = true

            let payload = this.setParams(page)

            const res = await this.$api.post('api/verbatims/filter', payload)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.verbatims = res.data.verbatims
                this.total_results = res.data.total
                let lastPage = Math.ceil(res.data.total/res.data.size)
                this.showNextBtn = res.data.page+1 <= lastPage
                this.page = res.data.page
                this.ghost.uuids = null
            }
        },

        initFilter () {
             this.ghost = {
                project: this.param,
                query: '',
                corpus: '',
                tag: '',
                page: 1,
                sort_on: '',
                sort_tye: ''
            }
        },

        loadProjectData () {
            let param = this.$router.currentRoute._value.params.id
            this.$store.dispatch('projects/getProject', param)
            this.$store.dispatch('projects/getProjectCorpus', param)
            this.$store.dispatch('projects/getProjectInfos', param)
        },

        selectTagModal (v) {
            this.selectedVerbatim = v
            this.$eventBus.$emit('selectVerbatimTags', v.tags)
            window.$('#selectEtiquetteModal').modal('show')
        },

        addTagModal (v) {
            this.selectedVerbatim = v
            this.$eventBus.$emit('addVerbatimTag')
            window.$('#addEtiquetteModal').modal('show')
        },

        editTagModal (tag) {
            this.selectedTag = tag
            window.$('#editEtiquetteModal').modal('show')
        },

        voirTagModal (text) {
            if (text) {
                this.selectedTagText = text
                window.$('#voirTextModal').modal('show')
            }
        },

        remove (tag, verbatim) {
            this.selectedTag = {
                "tag": tag,
                "verbatim": verbatim
            }
            window.$('#confirmModal').modal('show')
        },

        async confirmRemoveEtiquette (tag) {
            this.isLoading = true
            let page = JSON.parse(localStorage.getItem('current_page'))
            let formdata = new FormData()
            formdata.append('tag', tag.tag.tag)
            formdata.append('verbatim', tag.verbatim.uuid)
            formdata.append('project', this.param)

            const res = await this.$api.post('api/verbatims/remove/tag', formdata)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.ghost.uuids = this.verbatims.map(v => v.uuid)
                this.loadFilters(page)
            }
        },

        async saveTags (payload) {
            this.isLoading = true

            let postData = {}
            if (payload.type == 'many') {
                postData = {
                    "project": this.param,
                    "verbatim": payload.verbatim,
                    "tags": payload.tags,
                }
            } else {
                postData = {
                    "project": this.param,
                    "verbatim": payload.verbatim,
                    "tags": [payload.tag],
                }
            }

            const res = await this.$api.post('api/verbatims/add/tag', postData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.ghost.uuids = this.verbatims.map(v => v.uuid)
                this.loadProjectData()
                this.loadFilters(payload.page)
            }
        },
    }
}
</script>
