<template lang="html">
    <section class="signin-page">
        <div class="container">
            <div class="row">

                <div class="col-sm-4"></div>

                <div class="col-sm-4" v-show="!isLoading">
                    <div class="alert alert-info alert-dismissible fade show" role="alert" v-show="showMsg">
                        <i class="feather icon-info mr-10"></i>
                        {{ msg }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="login-form">
                        <div class="logo-name text-center">Analytics</div>
                        <div class="title text-center">Mot de passe oublié</div>

                         <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <div class="inner-addon left-addon">
                                    <i class="glyphicon feather icon-user"></i>
                                    <input type="email"
                                        name="email"
                                        placeholder="Email"
                                        class="form-control form-control-lg input-white"
                                        :disabled="isLoading"
                                        v-model="ghost.email"
                                        required
                                    >
                                </div>
                            </div>

                            <div class="mt-20">
                                <button
                                    class="btn btn-primary btn-block btn-lg"
                                    @click="reset()"
                                    :disabled="isLoading"
                                >
                                    <i class="feather icon-log-in pull-right"></i>
                                    {{ isLoading ? 'Réinitialisation en cours...' : 'Réinitialiser le mot de passe'}}
                                </button>
                            </div>

                            <div class="links">
                                <router-link
                                    :to="{ name: 'Signin', params: {} }"
                                    :disabled="isLoading"
                                    >Connexion</router-link>
                            </div>
                       </form>
                    </div>

                </div>

                <Loaders v-show="isLoading"></Loaders>

                <div class="col-sm-4"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ResetPassword',

    data: () => ({
        msg: '',
        showMsg: false
    }),

    methods: {
        /**
         * SIGNIN
         */
        async reset () {
            if (this.ghost.email !== '') {
                this.isLoading = true

                let formData = new FormData()
                formData.append('email', this.ghost.email)

                const res = await this.$api.post('/request/password/reset', formData)
                    .catch(error => {
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur de connexion', str)
                    })

                this.isLoading = false

                if (res) {
                    this.showMsg = true
                    this.msg = res.data
                    this.ghost.email = ''
                }
            } else {
                this.$swal.error('Validation', 'Saisissez l\'adresse email et validez à nouveau')
            }
        }
    }
}
</script>
