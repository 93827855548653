<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="displayVerbatimsModal"
    >
        <div class="modal-dialog modal-dialog-centered" v-show="!isLoading">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Verbatims liés</h4>

                    <div class="items">
                        <div
                            class="item"
                            v-for="v in verbatims"
                            :key="v.uuid"
                        >
                            <span @click="confirm(v)">{{ v.title }}</span>
                            <br><a :href="v.url" target="_blank">{{ v.domain }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loaders v-show="isLoading"></Loaders>
    </div>
</template>

<script>
export default {
    name: 'displayVerbatimsModal',
    emits: ['confirmed'],

    props: {
        verbatimModalObject: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        verbatims: [],
        projectModalUuid: {},
    }),

    mounted () {

    },

    watch: {
        verbatimModalObject: {
            immediate: true,
            handler: function (val) {
                if (val.uuid){
                    this.getKeyword(val)
                    this.projectModalUuid = { project: val.project }
                }
            }
        }
    },

    methods: {
        async getKeyword(keyword){
            this.isLoading = true
            const payload = {
                "project": keyword.project
            }

            const res = await this.$api.get(`api/keywords/${keyword.uuid}/show`, {params: payload} )
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.verbatims = res.data.verbatims
            }
        },

        async confirm (v) {
            this.isLoading = true

            const res = await this.$api.get(`api/verbatims/${v.uuid}/show`, {params: this.projectModalUuid})
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                localStorage.setItem('textContent', JSON.stringify(res.data.text))
                let routeData = this.$router.resolve({ name: 'displayVerbatimText', params: {p_uuid: res.data.project, v_uuid: res.data.uuid } })
                window.open(routeData.href, '_blank')
            }
        },

        close () {
            window.$('.modal').modal('hide')
        },
    }
}
</script>
