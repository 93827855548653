
import Swal from 'sweetalert2'

export default {
    install: (app) => {
        app.config.globalProperties.$swal = {
            success(title, msg) {
                return Swal.fire({
                    title,
                    position: 'top-end',
                    text: msg ? msg : '',
                    type: 'success',
                    timer: 2000
                })
            },

            error(title, msg) {
                Swal.fire({
                    title,
                    position: 'top-end',
                    text: msg ? msg : '',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 5000
                })
            }
        }
    }
}
