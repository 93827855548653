<template lang="html">
    <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
            <div class="card-body">
                <div class="charts-container float-right">
                  <div :class="'pie-wrapper progress-' + progression">
                    <span class="label">{{ progression }}<span class="smaller">%</span></span>
                    <div class="pie">
                      <div class="left-side half-circle"></div>
                      <div class="right-side half-circle"></div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IzyProgress',

    props: {
        taux: {
            type: Object,
            default: () => {}
        },

        canShow: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        progression () { return this.taux ? Math.round(this.taux.progression) : 0 }
    }
}
</script>
