<template>
    <div class="container-fluid">
        <h4 class="mt-20">Verbatim</h4>

        <div class="mt-20 mb-20">
            <div class="page-content" v-html="text"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'displayVerbatimText',

    computed: {
        text () {
            return JSON.parse(localStorage.getItem('textContent'))
        }
    }
}
</script>
