import {
    createWebHistory,
    createWebHashHistory,
    createRouter
} from "vue-router"

import Guard from '@/services/middleware'

import User from '@/views/users/user'
import Users from '@/views/users/users'
import Signin from '@/views/auth/signin'
import ResetPassword from '@/views/auth/reset-password'
import ConfirmResetPassword from '@/views/auth/confirm-reset-password'
import CreatePassword from '@/views/auth/create-password'
import Projects from '@/views/projects/projects'
import Project from '@/views/projects/project/show'
import Verbatims from '@/views/projects/project/verbatims/verbatims'
import Keywords from '@/views/projects/project/keywords/keywords'
import Lexiques from '@/views/projects/project/lexiques/lexiques'
import DisplayVerbatimText from '@/views/projects/project/keywords/display-verbatim-text'

import Corpus from '@/views/corpus/corpus'
import Account from '@/views/users/account'
import CorpusShow from '@/views/corpus/show'
import Settings from '@/views/dashboard/settings'
import Clients from '@/views/clients/clients'
import Dashboard from '@/views/dashboard/dashboard'

const routes = [
    { path: '/signin', name: 'Signin', component: Signin },
    { path: '/reset/password', name: 'ResetPassword', component: ResetPassword },
    { path: '/user/:id/password/reset', name: 'ConfirmResetPassword', component: ConfirmResetPassword },
    { path: '/user/:id/password/create', name: 'CreatePassword', component: CreatePassword },
    { path: '/', name: 'Home', component: Dashboard, beforeEnter: Guard.auth },
    { path: '/users', name: 'Users', component: Users, beforeEnter: Guard.auth },
    { path: '/users/:id', name: 'User', component: User, beforeEnter: Guard.auth },
    { path: '/corpus', name: 'Corpus', component: Corpus, beforeEnter: Guard.auth },
    { path: '/corpus/:id', name: 'CorpusShow', component: CorpusShow, beforeEnter: Guard.auth },
    { path: '/clients', name: 'Clients', component: Clients, beforeEnter: Guard.auth },
    { path: '/account', name: 'Account', component: Account, beforeEnter: Guard.auth },
    { path: '/projects', name: 'Projects', component: Projects, beforeEnter: Guard.auth },
    { path: '/settings', name: 'Settings', component: Settings, beforeEnter: Guard.auth },
    { path: '/nav-projects', name: 'nav-projects', component: Projects, beforeEnter: Guard.auth  },
    { path: '/nav-clients', name: 'nav-clients', component: Projects, beforeEnter: Guard.auth  },
    { path: '/projects/:id', name: 'Project', component: Project, beforeEnter: Guard.auth },
    { path: '/projects/:id/verbatims', name: 'Verbatims', component: Verbatims, beforeEnter: Guard.auth },
    { path: '/projects/:id/keywords', name: 'Keywords', component: Keywords, beforeEnter: Guard.auth },
    { path: '/projects/:id/lexiques', name: 'Lexiques', component: Lexiques, beforeEnter: Guard.auth },
    { path: '/projects/:p_uuid/keywords/:v_uuid', name: 'displayVerbatimText', component: DisplayVerbatimText, beforeEnter: Guard.auth },

    // Leave this one at the bottom
    { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory('/'),
    hash: createWebHashHistory(),
    routes,
})

export default router
