<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="recapModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Récapitulatif</h4>

                    <div class="mt-20 pb-20">
                        <div class="primary fs-16 mt-10">
                            <ul class="list-unstyled">
                                <li>Nombre de fichiers à importer :  {{ total }}</li>
                                <li v-if="success > 0">Nombre de fichiers importés avec succès : {{ success }}</li>
                                <li v-if="errors.length > 0">Nombre de fichiers erreurs : {{ errors.length }}</li>
                                <li v-if="incorrect.length > 0">Nombre de fichiers de type incorrect : {{ incorrect.length }}</li>
                                <li v-if="existing.length > 0">Nombre de fichiers existant : {{ existing.length }}</li>
                            </ul>
                        </div>

                        <div class="mt-10" v-if="errors.length > 0">
                            <div class="bold primary">Liste des fichiers erreurs</div>
                            <ol class="ml-20">
                                <li v-for="f in errors" :key="f">{{ f }}</li>
                            </ol>
                        </div>

                        <div class="mt-10" v-if="incorrect.length > 0">
                            <div class="bold primary">Liste des fichiers de type incorrect</div>
                            <ol class="ml-20">
                                <li v-for="f in incorrect" :key="f">{{ f }}</li>
                            </ol>
                        </div>

                        <div class="mt-10" v-if="existing.length > 0">
                            <div class="bold primary">Liste des fichiers existant</div>
                            <ol class="ml-20">
                                <li v-for="f in existing" :key="f">{{ f }}</li>
                            </ol>
                        </div>

                        <div class="row">
                            <div class="col-3"></div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-secondary mt-20"
                                    @click="cancel()"
                                >{{ 'Fermer' }}</button>
                            </div>

                            <div class="col-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'recapModal',

    props: {
        success: { type: Number, default: 0 },
        errors: { type: Array, default: () => [] },
        existing: { type: Array, default: () => [] },
        incorrect: { type: Array, default: () => [] },
    },

    computed: {
        total () {
            return this.success + this.errors.length
            + this.existing.length + this.incorrect.length
        }
    },

    methods: {
        cancel () {
            this.$emit('cancelled')
            window.$('.modal').modal('hide')
        },
    }
}
</script>
