window.$ = window.jquery = require('jquery')
window.debounce = require('debounce')

import 'bootstrap'
import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Store from './store'
import GlobalMixins from './mixins/global'
import ApiPlugin from './plugins/api'
import AuthPlugin from './plugins/auth'
import SwalPlugin from './plugins/swal'
import ToastrPlugin from '@meforma/vue-toaster'
import BusPlugin from './plugins/bus'

import IzyBtn from '@/components/ui/btn/btn'
import ConfirmModal from '@/components/modals/confirm'
import Sidebar from '@/components/commons/sidebar'
import Loaders from '@/components/commons/loaders'
import MainSidebar from '@/components/commons/main-sidebar'
import IzyPaginate from '@/components/commons/izy-paginate'
import IzyCheckbox from '@/components/commons/checkbox'
import IzyApplyCheckbox from '@/components/commons/apply-checkbox'
import IzyProgress from '@/components/commons/izy-progress'
import FiltreNumerique from '@/components/commons/filtre-numerique'
import MultiselectCheckbox from '@/components/commons/multiselect-checkbox'

const app = createApp(App)

app.component('izy-btn', IzyBtn)
app.component('Sidebar', Sidebar)
app.component('ConfirmModal', ConfirmModal)
app.component('Loaders', Loaders)
app.component('IzyPaginate', IzyPaginate)
app.component('IzyCheckbox', IzyCheckbox)
app.component('IzyApplyCheckbox', IzyApplyCheckbox)
app.component('IzyProgress', IzyProgress)
app.component('MainSidebar', MainSidebar)
app.component('FiltreNumerique', FiltreNumerique)
app.component('MultiselectCheckbox', MultiselectCheckbox)

app.use(router)
    .use(Store)
    .use(ApiPlugin)
    .use(AuthPlugin)
    .use(SwalPlugin)
    .use(ToastrPlugin, {
        position: 'bottom-right',
        duration: 10000,
    })
    .use(BusPlugin)
    .mixin(GlobalMixins)
    .mount('#app')
