<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-paperclip"></i>
                            Corpus
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">
            <div class="_searchbar">
              <div class="search_form">
                  <form @submit.prevent="loadCorpus()" class="_form">
                      <div class="form-group form-group-lg">
                          <div class="inner-addon left-addon">
                              <i class="glyphicon feather icon-search"></i>

                              <input type="text"
                                  name="name"
                                  placeholder="Saisir le nom d'un corpus"
                                  autocomplete="off"
                                  class="form-control"
                                  v-model="ghost.name"
                              >
                          </div>
                      </div>
                  </form>
              </div>
              <div class="select">
                <div class="form-group">
                    <select
                        class="form-control"
                        name="client"
                        v-model="ghost.client"
                        required
                    >
                        <option value="">Sélectionner un client</option>
                        <option
                            v-for="c in clients"
                            :key="c.uuid"
                            :value="c.uuid"
                        >{{ c.name }}</option>
                    </select>
                </div>
              </div>
              <div class="filter_btn">
                  <button
                      class="btn btn-secondary"
                      @click="loadCorpus()"
                      :disabled="isLoading"
                  >
                      Filtrer
                  </button>
              </div>
              <div class="add_btn text-right">
                <button
                    class="btn btn-primary"
                    @click="showAddCorpusModal()"
                    :disabled="isLoading"
                >
                    Nouveau corpus
                </button>
              </div>
            </div>


            <div class="izy-table mt-20">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-2">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('name')"
                            >
                                Nom
                                <i class="feather icon-chevron-up" v-if="!sort_items.name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.name"></i>
                            </div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">Créé par</div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">Modifié par</div>
                        </div>

                        <div class="col-2">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('created_at')"
                            >
                                Création
                                <i class="feather icon-chevron-up" v-if="!sort_items.created_at"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.created_at"></i>
                            </div>
                        </div>

                        <div class="col-1">
                            <div class="izy-table-th">
                                Exploitable
                            </div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">
                                Client
                            </div>
                        </div>

                        <div class="col-1">
                            <div
                                class="izy-table-th"
                            >
                                #Fichiers
                            </div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div class="izy-table-row" v-for="c in corpus" :key="c.uuid">
                        <div class="row">
                            <div class="col-2">
                                <div class="izy-table-col">
                                    <i class="feather icon-trash-2 mr-2 pointer" @click="remove(c)" v-if="isEditOrDel(c)"></i>
                                    <span
                                        class="pointer name"
                                        @click="openCorpus(c)"
                                    >
                                        {{ c.name }}
                                    </span>
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ c.creator }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ c.updated_by }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ created_at(c.created_at) }}
                                </div>
                            </div>

                            <div class="col-1">
                                <div class="izy-table-col">
                                    {{ c.processable ? "Oui" : "Non" }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ c.client }}
                                </div>
                            </div>

                            <div class="col-1">
                                <div class="izy-table-col">
                                    {{ c.nb_files }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-20 text-center">
                    <izyPaginate
                        @nextPage="loadCorpus"
                        @previousPage="loadCorpus"
                        @currentPage="loadCorpus"
                        :showNextBtn="showNextBtn"
                        :page="page"
                    ></izyPaginate>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <AddCorpusModal @confirmed="confirmedAddCorpus" />
        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selected"
            :showAlert="true"
            @confirmed="confirmRemove" />

        <div></div>
    </div>
</template>

<script>
import _ from 'lodash'
import AddCorpusModal from './modals/add-modal'
import AuthService from '@/services/auth'

export default {
    name: 'Corpus',
    components: { AddCorpusModal },

    data: () => ({
        page: 1,
        showNextBtn: false,
        selected: {},
        corpus: [],
        sort_items:{
            'name': true,
            'created_at': true,
            'file': true
        },
        src: '',
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer ce corpus ?'
    }),

    computed: {
        corpusList () { return JSON.parse(localStorage.getItem('corpusList')) },
        more () { return localStorage.getItem('more') === 'true' },
        availableParams () { return JSON.parse(localStorage.getItem('availableParams')) },
        pageNumber () { return localStorage.getItem('pageNumber') },

        auth () {
            let user = AuthService.user()
            return user.account
        },

        user_uuid () {
            return this.auth.uuid.replace('-', '')
        },

        clients () {
            return this.$store.state.clients.clients
        },

        displayedClients () {
            let tab = []
            if (this.auth.role.role_code == 3) {
                tab = this.clients.filter(c => c.created_by == this.user_uuid)
            }

            if (this.auth.role.role_code == 1 || this.auth.role.role_code == 2) {
                tab = this.clients
            }

            return tab
        },

        isAvailable () {
            return this.auth.role.role_code == 1 || this.auth.role.role_code == 2
        },
    },

    mounted () {
        this.$store.dispatch('clients/getClients')
        this.resetGhost()
        this.$nextTick(() => {
            if(!_.isEmpty(this.corpusList)) {
                this.corpus = this.corpusList
                this.page = parseInt(this.pageNumber, 10)
                this.ghost = this.availableParams
                this.showNextBtn = this.more
                if (_.isEmpty(this.ghost.client)) this.ghost.client = ''
            }
            if(_.isEmpty(this.corpusList)) { this.loadCorpus() }
        })
    },

    methods: {
        isEditOrDel (corpus) {
            let isCreatedBy = this.user_uuid == this.sansTirets(corpus.created_by)
            return this.isAvailable || isCreatedBy
        },

        resetGhost () {
            this.ghost = {
                name: '',
                client: ''
            }
        },

        setParams (pay) {
            let payload = pay
            if (payload.name == null || payload.name == undefined || payload.name == '') delete payload.name
            if (payload.client == null || payload.client == undefined || payload.client == '') delete payload.client
            if (payload.sort_on == null || payload.sort_on == undefined || payload.sort_on == '') delete payload.sort_on
            if (payload.sort_type == null || payload.sort_type == undefined || payload.sort_type == '') delete payload.sort_type

            return payload
        },

        /**
        * Load Corpus List
        *
        * @return {void}
        */
        async loadCorpus (page = 1) {
            if (this.src !== 'save') {
                this.isLoading = true
            }

            const payload = {
                "page": page,
                "name": this.ghost.name,
                "client": this.ghost.client,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            let availableParams = this.setParams(payload)

            const res = await this.$api.get(`api/corpus/list`, { params: availableParams })
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.showNextBtn = res.data.more
                this.initCorpusList(res.data.corpus, res.data.more)
                this.src = ''
                this.page = page
                localStorage.setItem('more', res.data.more)
                localStorage.setItem('pageNumber', this.page)
                localStorage.setItem('availableParams', JSON.stringify(availableParams))
            }
        },

        /**
        * init Corpus List
        *
        */
        initCorpusList (corpusTab, more) {
            localStorage.setItem('corpusList', JSON.stringify(corpusTab))
            this.corpus = corpusTab
            this.showNextBtn = more
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadCorpus()
        },

        /**
        * Open Delete Corpus Modal
        *
        * @return {void}
        */
        remove (corpus) {
            this.selected = corpus
            window.$('#confirmModal').modal('show')
        },

        /**
        * Navigate to user logs
        *
        * @return {void}
        */
        openCorpus (item) {
            this.navigate({
                name: 'CorpusShow', params: { id: item.uuid }
            })
        },

        /**
         * Save user's details
         *
         * @return {void}
         */
        showAddCorpusModal () {
            this.$eventBus.$emit('addCorpus', this.clients)
            window.$('#addCorpusModal').modal('show')
        },

        /**
        * Navigate to user logs
        *
        * @return {void}
        */
        async confirmedAddCorpus (item) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('name', item.name)
            formData.append('client', item.client)
            formData.append('description', item.description)

            const res = await this.$api.post(`api/admin/corpus/store`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Corpus ajouté avec succès')
                this.loadCorpus()
            }
        },

        /**
        * Delete Corpus
        *
        * @return {void}
        */
        async confirmRemove (corpus) {
            this.isLoading = true

            const res = await this.$api.delete(`/api/admin/corpus/${corpus.uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Suppression effectuée avec succès')
                this.loadCorpus()
            }
        }
    }
}
</script>
