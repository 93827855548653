<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addProjectModal"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Nouveau projet</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="name"
                                    placeholder="Nom du projet"
                                    class="form-control form-control-lg"
                                    v-model="ghost.name"
                                    maxlength="50"
                                    required
                                >
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <select
                                            class="form-control form-control-lg"
                                            name="client"
                                            v-model="ghost.client"
                                            required
                                        >
                                            <option value="">Sélectionner un client</option>
                                            <option
                                                v-for="c in clients"
                                                :key="c.uuid"
                                                :value="c.uuid"
                                            >{{ c.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <!-- <VueMultiselect
                                            v-model="value"
                                            :options="options"
                                            label="name"
                                            track-by="uuid"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :multiple="true"
                                            placeholder="Sélectionner les corpus"
                                        /> -->
                                        <MultiselectCheckbox :options="options" @checked="onCheck"></MultiselectCheckbox>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <textarea
                                    placeholder="Description du projet"
                                    name="description" id="" rows="5"
                                    class="form-control"
                                    v-model="ghost.description"
                                ></textarea>
                            </div>
                        </form>


                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'ConfirmModal',
    emits: ['confirmed', 'cancelled'],

    props: {
        clients: { type: Array, default: () => []},
        options: { type: Array, default: () => []},
    },

    data: () => ({
        value: [],
    }),

    mounted () {
        this.$eventBus.$on('addProject', () => {
            this.resetGhost()
            this.value = []
        })
    },

    watch: {
        options (val) {
            return _.cloneDeep(val)
        }
    },

    computed: {
        user () {
            return this.$store.state.user
        },
    },

    methods: {
        addTag (newTag) {
            const tag = {
                uuid: newTag.uuid,
                name: newTag.name
            }

            this.value.push(tag)
        },

        confirm () {
            if (this.ghost.name == '') {
                this.$swal.error('Validation', 'Bien vouloir saisir le nom du projet')
            } else if (this.ghost.client == '') {
                this.$swal.error('Validation', 'Bien vouloir sélectionner un client pour ce projet')
            } else if (this.ghost.name !== '' && this.ghost.client !== '') {
                let corpus = this.value.map(v => v.uuid)
                const payload = Object.assign({}, this.ghost, { corpus: _.cloneDeep(corpus) })
                this.$emit('confirmed', payload)
                this.resetGhost()
                this.close()
            }
        },

        close () {
            window.$('.modal').modal('hide')
        },

        onCheck (val) {
            this.value = val
        },

        cancel () {
            this.$store.commit('projects/SET_NAMES', [])
            this.resetGhost()
            this.$emit('cancelled')
            this.close()
        },

        resetGhost () {
            this.ghost = {
                name: '',
                client: '',
                description: '',
                corpus: null,
            }
        },
    },

}
</script>
