<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="editLexiqueModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Editer un lexique</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent="confirm()">
                            <div class="form-group">
                                <input type="text"
                                    name="name"
                                    placeholder="Nom du lexique"
                                    class="form-control form-control-lg"
                                    v-model="ghost.name"
                                    required="required"
                                >
                            </div>
                        </form>


                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click.prevent="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'editLexiqueModal',
    emits: ['confirmed', 'cancelled'],

    props: {
        lexique: {
            type: Object,
            default: () => {}
        },
    },

    mounted () {
        this.$eventBus.$on('editLexique', (result) => {
            if (!_.isEmpty(result)) {
                this.ghost = Object.assign({}, result)
            }
        })
    },

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.ghost = Object.assign({}, this.lexique)
            this.close()
        }
    }
}
</script>
