<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-folder"></i>
                            Projets
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!isLoading" class="container-fluid mt-20 pb-40">
            <div class="text-right">
                <button
                    class="btn btn-primary"
                    @click="add()"
                    :disabled="isLoading"
                >
                    Nouveau projet
                </button>
            </div>

            <div class="izy-table mt-20">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-3">
                            <div class="izy-table-th pointer" @click="setSortingKey('name')">
                                Nom
                                <i class="feather icon-chevron-up" v-if="!sort_items.name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.name"></i>
                            </div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">Client</div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">Statut</div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th pointer" @click="setSortingKey('created_at')">
                                Création

                                <i class="feather icon-chevron-up" v-if="!sort_items.created_at"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.created_at"></i>
                            </div>
                        </div>

                        <div class="col-1">
                            <div class="izy-table-th">#Fichiers</div>
                        </div>

                        <div class="col-1">
                            <div class="izy-table-th">#Verbatims</div>
                        </div>

                        <div class="col-1">
                            <div class="izy-table-th">#Mot-clés</div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div class="izy-table-row" v-for="p in projects" :key="p.uuid">
                        <div class="row">
                            <div class="col-3">
                                <div class="izy-table-col">
                                    <i v-if="isEditOrDel(p)" class="feather icon-trash-2 mr-2 pointer" @click="remove(p)"></i>
                                    <span class="pointer name" @click="openProject(p)">
                                        {{ loadName(p) }}
                                    </span>
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ p.client.name }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ p.status }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ created_at(p.created_at) }}
                                </div>
                            </div>

                            <div class="col-1">
                                <div class="izy-table-col">
                                    {{ p.nb_files }}
                                </div>
                            </div>

                            <div class="col-1">
                                <div class="izy-table-col">
                                    {{ p.nb_verbatims }}
                                </div>
                            </div>

                            <div class="col-1">
                                <div class="izy-table-col">
                                    {{ p.nb_keywords }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-20 text-right" v-show="showMore">
                <button
                    class="btn btn-outline-primary"
                    @click="loadMore()"
                >Afficher Plus</button>
            </div>

        </div>

        <Loaders v-show="isLoading"></Loaders>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selectedProject"
            @confirmed="confirmRemove" />

        <AddProjectModal
            @confirmed="saveProject"
            :clients="clients"
            :options="all_corpus"
        />

        <div></div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import AuthService from '@/services/auth'
import ImagePlaceholder from '@/assets/images/placeholder.png'
import AddProjectModal from './add-project-modal'
import moment from 'moment'

export default {
    name: 'Home',
    components: { AddProjectModal },

    data: () => ({
        selectedProject: {},
        selectedCorpus: [],
        ImagePlaceholder,
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer ce projet ?',
        page: 1,
        src: '',
        sort_items:{
            'name': true,
            'created_at': true
        },
        projects: [],
        nextPage: null,
    }),

    mounted () {
        this.$nextTick(() => {
            if(!_.isEmpty(this.projectsList)) {
                this.projects = this.projectsList
                this.page = parseInt(this.pageNumber, 10)
            }
            if(_.isEmpty(this.projectsList)) {
                this.getProjects()
                this.getClients()
                this.getAllCorpus()
            }
        })
    },

    computed: {
        ...mapGetters('clients', {
            clients: 'getClients'
        }),

        ...mapGetters('corpus', {
            all_corpus: 'getAllCorpus'
        }),

        showMore () {
            return this.$store.state.projects.next_projects !== null
        },

        auth () {
            return AuthService.getAuth()
        },

        user () {
            return this.auth.account
        },

        user_uuid () {
            return this.user.uuid.replace('-', '')
        },

        isAvailable () {
            return this.auth.account.role.id == 1 || this.auth.account.role.id == 2
        },

        projectsList () { return JSON.parse(localStorage.getItem('projectsList')) },
        pageNumber () { return localStorage.getItem('pageNumber') },
        more () { return localStorage.getItem('more') === 'true' },
    },

    methods: {
        isEditOrDel (p) {
            let isCreatedBy = this.user_uuid == this.sansTirets(p.created_by)
            return this.isAvailable || isCreatedBy
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.getProjects()
        },

        loadName (project) {
            return !_.isEmpty(project) ? this.truncateName(project.name) : ''
        },

        createdAt(dat) {
            return moment(dat).format('DD/MM/YYYY')
        },

        add () {
            this.$eventBus.$emit('addProject')
            window.$('#addProjectModal').modal('show')
        },

        remove (project) {
            this.selectedProject = project
            window.$('#confirmModal').modal('show')
        },

        async confirmRemove (project) {
            this.isLoading = true

            const response = await this.$api.delete(`api/admin/projects/${project.uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.src = 'remove'
                this.$swal.success('Confirmation', 'Projet supprimé')
                this.getProjects()
            }
        },

        async saveProject (payload) {
            this.isLoading = true

            const response =  await this.$api.post('api/admin/projects/store', payload)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.isLoading = false
                this.$store.commit('projects/ADD_PROJECT', response.data)
                // this.getProjects()
            }
        },

        /**
         * Open a project
         *
         * @return {void}
         */
        openProject (p) {
            let id = p.uuid
            localStorage.setItem('project', JSON.stringify(p))
            this.navigate({ name: 'Project', params: { id } })
        },

        resetGhost () {
            this.ghost = {
                name: '',
                description: ''
            }
        },

        /**
        * Get all CORPUS
        *
        * @return {void}
        */
        async getAllCorpus () {
            this.isLoading = true

            const response = await this.$api.get('api/corpus/all', {params: {processable: true}})
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.$store.commit('corpus/SET_ALL_CORPUS', response.data)
            }

            this.isLoading = false
        },

        async loadMore () {
            if(this.nextPage !== null) {
                this.isLoading = true

                let url = 'api/projects/list'

                const payload = {
                    "page": this.nextPage,
                    "sort_on": this.ghost.sort_on,
                    "sort_type": this.ghost.sort_type,
                }

                const response = await this.$api.get(url, {params: payload })
                    .catch(error => {
                        this.isLoading = false
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur', str)
                    })

                if (response) {
                    this.isLoading = false
                    this.$store.commit('projects/SET_NEXT_PROJECTS', response.data.next)
                    this.page = response.data.next !== null ? response.data.next : this.nextPage
                    this.nextPage = response.data.next
                    localStorage.setItem('pageNumber', this.page)
                    // this.initProjectsList(response.data)
                    this.projects = [...this.projects, ...response.data.projects]
                    localStorage.setItem('projectsList', JSON.stringify(this.projects))
                }
            }
        },

        /**
        * Get all projects
        *
        * @return {void}
        */
        async getProjects (page = 1) {
            if (this.src == '') {
                this.isLoading = true
            }

            let url = 'api/projects/list'

            const payload = {
                "page": page,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            const response = await this.$api.get(url, {params: payload})
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.isLoading = false
                this.$store.commit('projects/SET_NEXT_PROJECTS', response.data.next)
                this.page = response.data.next !== null ? response.data.next : page
                this.nextPage = response.data.next
                localStorage.setItem('pageNumber', this.page)
                // this.initProjectsList(response.data)
                this.projects = response.data.projects
                localStorage.setItem('projectsList', JSON.stringify(this.projects))
            }
            
            this.src = ''
        },

        /**
        * init Projects List
        *
        */
        initProjectsList (objet) {
            this.projects = [...this.projects, ...objet.projects]
            localStorage.setItem('projectsList', JSON.stringify(this.projects))
        },

        /**
        * Get all clients
        *
        * @return {void}
        */
        async getClients () {
            let url = 'api/admin/clients/list'

            const response = await this.$api.get(url)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.$store.commit('clients/SET_CLIENTS',response.data.clients)
            }
        },
    }
}
</script>
