<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addEtiquettekModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Ajouter l'étiquette</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent="confirm()">
                            <div class="form-group">
                                <input type="text"
                                    name="tags"
                                    placeholder="Nom de l'étiquette"
                                    class="form-control form-control-lg"
                                    v-model="ghost.tags"
                                    required="required"
                                    maxlength="30"
                                >
                            </div>
                        </form>


                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click.prevent="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'addEtiquetteModal',
    emits: ['confirmed', 'cancelled'],

    props: {
        keyword: {
            type: Object,
            default: () => {}
        },

        page: {
            type: Number,
            default: 1
        },
    },

    mounted () {
        this.$eventBus.$on('addKeywordTag', () => {
            this.resetGhost()
        })
    },

    methods: {
        confirm () {
            if (this.ghost.tags == '') {
                this.$swal.error('Validation', `L'étiquette ne peut être vide`)
            } else {
                this.ghost.keyword = this.keyword.uuid
                this.ghost.page = this.page
                this.$emit('confirmed', this.ghost)
                this.close()
            }
        },

        close () {
            this.resetGhost()
            window.$('.modal').modal('hide')
        },

        resetGhost () {
            this.ghost = {
                keyword: this.keyword.uuid,
                tags: ''
            }
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        }
    }
}
</script>
