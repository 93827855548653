<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-share-2"></i>
                            Clients
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">
            <div class="text-right">
                <button
                    class="btn btn-primary"
                    @click="addClientModal()"
                    :disabled="isLoading"
                >
                    Nouveau client
                </button>
            </div>


            <div class="izy-table mt-20">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-4">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('name')"
                            >
                                Nom
                                <i class="feather icon-chevron-up" v-if="!sort_items.name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.name"></i>
                            </div>
                        </div>

                        <div class="col-8">
                            <div class="izy-table-th">Description</div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div
                        class="izy-table-row"
                        v-for="client in clients"
                        :key="client.uuid"
                    >
                        <div class="row pointer">
                            <div class="col-4">
                                <div class="izy-table-col">
                                    <i class="feather icon-trash-2 mr-10" @click="removeClient(client)" v-if="isEditOrDel(client)"></i>
                                    <span @click="editClientModal(client)">{{ client.name }}</span>
                                </div>
                            </div>

                            <div class="col-8">
                                <div class="izy-table-col">
                                    {{ client.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <AddClientModal @confirmed="saveClient"></AddClientModal>

        <EditClientModal
            :client="selectedClient"
            @confirmed="saveClientEdit"
            :canEdit="canAction"
        ></EditClientModal>

        <ConfirmModal
            :id="'confirmClientModal'"
            :title="deleteClientModalTitle"
            :message="deleteClientModalDescription"
            :resource="selectedClient"
            @confirmed="confirmRemoveClient" />

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddClientModal from './add-client-modal'
import EditClientModal from './edit-client-modal'
import AuthService from '@/services/auth'

export default {
    name: 'Clients',
    components: { AddClientModal, EditClientModal },

    data: () => ({
        src: '',
        canAction: false,
        sort_items:{
            'name': true
        },
        page: 1,
        selectedClient: {},
        deleteClientModalTitle: 'Confirmation',
        deleteClientModalDescription: 'Voulez-vous vraiment supprimer ce client ?',
    }),

    mounted () {
        this.loadClients()
    },

    computed: {
        ...mapGetters('clients', {
            clients: 'getClients'
        }),

        auth () {
            let user = AuthService.user()
            return user.account
        },

        user_uuid () {
            return this.auth.uuid.replace('-', '')
        },

        displayedClients () {
            let tab = []
            if (this.auth.role.role_code == 3) {
                tab = this.clients.filter(c => c.created_by == this.user_uuid)
            }

            if (this.auth.role.role_code == 1 || this.auth.role.role_code == 2) {
                tab = this.clients
            }

            return tab
        },

        isAvailable () {
            return this.auth.role.role_code == 1 || this.auth.role.role_code == 2
        }
    },

    methods: {
        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadClients()
        },

        editClientModal (client) {
            this.selectedClient = client
            this.canAction = this.isEditOrDel(client)
            if (this.canAction) {
                window.$('#editClientModal').modal('show')
            }
        },

        addClientModal () {
            this.$eventBus.$emit('addClient')
            window.$('#addClientModal').modal('show')
        },

        removeClient (client) {
            this.selectedClient = client
            window.$('#confirmClientModal').modal('show')
        },

        async confirmRemoveClient (client) {
            this.isLoading = true

            const response = await this.$api.delete(`api/admin/clients/${client.uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.src = 'remove'
                this.$swal.success('Confirmation', 'Client supprimé')
                this.loadClients()
            }
        },

        async saveClient (payload) {
            this.isLoading = true
            let formData = new FormData()
            formData.append('name', payload.name)
            formData.append('description', payload.description)

            const response = await this.$api.post('api/admin/clients/store', formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.src = 'add'
                this.loadClients()
            }
        },

        async saveClientEdit (payload) {
            this.isLoading = true
            let formData = new FormData()
            formData.append('name', payload.name)
            formData.append('description', payload.description)

            const response = await this.$api.patch(`api/admin/clients/${payload.uuid}/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.src = 'add'
                this.loadClients()
            }
        },

        /**
        * Get all clients
        *
        * @return {void}
        */
        async loadClients (page=1) {
            if (this.src !== 'add' && this.src !== 'remove') {
                this.isLoading = true
            }

            const payload = {
                "page": page,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            const response = await this.$api.get('api/admin/clients/list', {params: payload})
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            this.isLoading = false

            if (response) {
                this.$store.commit('clients/SET_CLIENTS', response.data.clients)
                this.page = page
            }
        },

        isEditOrDel (client) {
            let isCreatedBy = this.user_uuid == this.sansTirets(client.created_by)
            return this.isAvailable || isCreatedBy
        }
    }
}
</script>
