import moment from 'moment'
import _ from 'lodash'

export default {
    data: () => ({
        ghost: {},
        showErrors: false,
        isLoading: false,
        errors: [],
        selectedTags: [],
    }),

    methods: {
        /**
        * Navigate back
        *
        */
        goback (step) {
            this.$router.go(step ? step : -1)
        },

        /**
        * [navigate description]
        *
        * @param  Object route
        *
        * @return void
        */
        navigate (route) {
            this.$router.push(route)
                .catch(e => console.log('navigation error catch', e))
        },

        go (name) {
            this.navigate({ name })
        },

        /**
        * validate form elements
        *
        * @return {Boolean} [description]
        */
         async validate () {
            await this.$validator.validateAll()

            if (this.errors.items.length > 0)
                this.showErrors = true

            return this.errors.items.length === 0
        },

        /**
        * display date on format
        *
        * @return {Date}
        */
        created_at (dt) {
            return moment(dt).format('DD/MM/YYYY')
        },

        showError () {
            window.$('#errorModal').modal('show')
        },

        sansTirets (text) {
            if (!_.isEmpty(text)) {
                return text.replace('-', '')
            }
        },

        truncateText (text) {
            if (text) {
                return text.split(" ").splice(0, 15).join(" ").concat('')
            } else {
                return text
            }
        },

        truncateTitle (title) {
            if (title) {
                return title.split(" ").splice(0, 10).join(" ").concat('')
            } else {
                return title
            }
        },

        truncateCorpus (corpus) {
            if (corpus) {
                return corpus.split("_").splice(0, 3).join("_").concat('')
            } else {
                return corpus
            }
        },

        truncateName (name) {
            if (name) {
                return name.split(" ").splice(0, 3).join(" ").concat('')
            } else {
                return name
            }
        },

        truncateDescription (description) {
            if (description) {
                return description.split(" ").splice(0, 50).join(" ").concat('')
            } else {
                return description
            }
        },
    }
}
