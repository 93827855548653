import env from '../../env'

export default {
    user () {
        return JSON.parse(localStorage.getItem('auth'))
    },

    getToken () {
        return localStorage.getItem(env.token)
    },

    setToken (token) {
        localStorage.setItem(env.token, token)
    },

    logout () {
        localStorage.clear()
    },

    check () {
        return localStorage.getItem(env.token)
    },

    setAuth (params) {
        localStorage.setItem('auth', JSON.stringify(params))
    },

    getAuth () {
        return JSON.parse(localStorage.getItem('auth'))
    }
}
