<template lang="html">
    <section class="signin-page">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 mt-20 text-center" v-if="message !== ''">
                    <div :class="`alert alert-${alert} alert-dismissible fade show`" role="alert">
                        <i class="feather icon-info mr-10"></i>
                        {{ message }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

                <!-- le formulaire de connexion -->
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                    <div class="login-form">
                        <div class="logo-name text-center">Analytics</div>
                        <div class="title text-center">Connexion</div>

                         <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <div class="inner-addon left-addon">
                                    <i class="glyphicon feather icon-user"></i>
                                    <input type="email"
                                        name="email"
                                        placeholder="Email"
                                        class="form-control form-control-lg input-white"
                                        :disabled="isLoading"
                                        v-model="ghost.email"
                                    >
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="inner-addon left-addon">
                                    <i class="glyphicon feather icon-lock"></i>
                                    <input type="password"
                                        name="password"
                                        placeholder="Password"
                                        v-model="ghost.password"
                                        :disabled="isLoading"
                                        class="form-control form-control-lg input-white"
                                    >
                                </div>
                            </div>

                            <div class="links">
                                <router-link
                                    :to="{ name: 'ResetPassword', params: {} }"
                                    :disabled="isLoading"
                                    >Mot de passe oublié</router-link>
                            </div>

                            <div class="mt-20">
                                <button
                                    class="btn btn-primary btn-block btn-lg"
                                    @click="signin()"
                                    :disabled="isLoading"
                                >
                                    <i class="feather icon-log-in pull-right"></i>
                                    {{ isLoading ? 'Connexion en cours...' : 'Connexion'}}
                                </button>
                            </div>
                       </form>
                    </div>

                </div>

                <Loaders v-show="isLoading"></Loaders>

                <div class="col-sm-4"></div>
            </div>
        </div>
    </section>
</template>

<script>
import AuthService from '@/services/auth'
import ApiService from '@/services/api'

export default {
    name: 'Signin',

    computed: {
        message () {
            return this.$store.state.message
        },

        alert () {
            return this.$store.state.alert
        }
    },

    methods: {
        /**
         * SIGNIN
         */
        async signin () {
            this.isLoading = true

            const res = await this.$api.post('/login', this.ghost)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur de connexion', str)
                })

            this.isLoading = false

            if (res) {
                this.$store.commit('SET_USER', res.data)
                AuthService.setAuth(res.data)
                AuthService.setToken(res.data.token)
                ApiService.setToken(res.data.token)
                localStorage.setItem('roleCode', res.data.account.role.role_code)
                this.go('Home')
            }
        }
    }
}
</script>
