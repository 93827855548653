<template lang="html">
    <nav aria-label="Page navigation example" class="btn-paginate">
        <ul class="pagination">
            <li class="">
                <button
                    type="button"
                    class="page-link"
                    @click="previous()"
                    v-show="page > 1"
                >
                   Précédent
               </button>
            </li>

            <li class="page-item">
                <button
                    type="button"
                    class="page-link pages-btns"
                    @click="current()"
                >
                        {{ page }}
                </button>
            </li>

            <li class="">
                <button
                    type="button"
                    class="page-link"
                    @click="next()"
                    v-show="showNextBtn"
                >
                    Suivant
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "izyPaginate",

    props: {
        showNextBtn: {
            type: Boolean,
            default: false
        },

        page: {
            type: Number,
            default: 1
        },
    },

    data: () => ({
        // page: 1
    }),

    methods: {
        previous () {
            let p = this.page
            let current = p-1 < 1 ? 1 : p-1
            this.$emit('previousPage', current)
        },

        next () {
            let p = this.page
            let current = p+1
            this.$emit('nextPage', current)
        },

        current () {
            this.$emit('currentPage', this.page)
        },
    }
}
</script>
