<template>
    <div id="project-wrapper" class="toggled">
        <Sidebar />

        <div id="page-content-wrapper" v-show="!isLoading">
            <div class="page-content-header pointer" @click="loadFilters()">
                <h4>{{ project.name }}</h4>
            </div>

            <div class="text-right mt-10" v-if="progression>0">
                <IzyProgress
                    :taux="task"
                ></IzyProgress>
            </div>

            <div class="container-fluid">
                <div class="page-content">
                    <div class="verbatims mt-40">
                        <div class="consider">{{ total_verbatims }} Verbatims considérés</div>
                        <div class="result-text float-right">{{ total_keywords }} mots-clés trouvés</div>

                        <div class="mt-40">
                            <div class="filtre">
                                <form class="_form" @submit.prevent="loadFilters()">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="borders">
                                                <h4>Filtres sur verbatims:</h4>
                                                <div class="form-group mt-10">
                                                    <label for="">Appartient au corpus</label>
                                                    <VueMultiselect
                                                    v-model="value_corpus"
                                                    label="name"
                                                    track-by="uuid"
                                                    placeholder="Sélectionner les corpus"
                                                    :options="p_corpus"
                                                    :multiple="true"
                                                    :taggable="true"
                                                    @tag="addCorpusTag"
                                                    />
                                                </div>

                                                <div class="form-group">
                                                    <label for="verbatim_tag">Contient étiquette(s)</label>
                                                    <VueMultiselect
                                                        v-model="verbatim_value"
                                                        :options="options"
                                                        :close-on-select="true"
                                                        :clear-on-select="false"
                                                        :multiple="true"
                                                        placeholder="Sélectionner les étiquettes"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-9 borders">
                                            <h4>Filtres sur mots-clés:</h4>
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="form-group mt-10">
                                                        <label for="keyword_tag">Contient étiquette(s)</label>
                                                        <VueMultiselect
                                                            v-model="keyword_value"
                                                            :options="options"
                                                            :close-on-select="true"
                                                            :clear-on-select="false"
                                                            :multiple="true"
                                                            placeholder="Sélectionner les étiquettes"
                                                        />
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Types grammaticaux</label>
                                                        <select
                                                            class="form-control input-white haut"
                                                            v-model="ghost.pos"
                                                            name="pos"
                                                        >
                                                            <option value="">Tous</option>
                                                            <option
                                                                v-for="pos in options_pos"
                                                                :value="pos"
                                                                :key="pos"
                                                            >{{ pos }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4">
                                                    <div class="form-group m-10">
                                                        <label for="cluster">Cluster</label>
                                                        <select
                                                            class="form-control input-white haut"
                                                            v-model="ghost.cluster"
                                                            name="cluster"
                                                        >
                                                            <option value="">Tous</option>
                                                            <option
                                                                v-for="cluster in project_infos.clusters"
                                                                :value="cluster.uuid"
                                                                :key="cluster.uuid"
                                                            >{{ cluster.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group mt-10">
                                                        <label for="keywords">Contient l'expression</label>
                                                        <input type="text"
                                                            name="keywords"
                                                            class="form-control input-white haut"
                                                            placeholder="Entrer l’expression"
                                                            v-model="ghost.query"
                                                            @keyup.enter="loadFilters()"
                                                        >
                                                    </div>
                                                </div>

                                                <div class="col-sm-4 mt-10">
                                                    <label for="">Valeurs numériques</label>
                                                    <div class="_filtre-list">
                                                        <div class="_items">
                                                            <FilterLine
                                                                v-for="i in itemsAdded"
                                                                :key="i.value"
                                                                :item="i"
                                                                @edited="edited"
                                                                @deleted="removeFilterItem"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="_filter-form row no-gutters">
                                                        <div class="col-sm-4">
                                                            <div class="_select" v-if="itemsNums.length > 0">
                                                                <select
                                                                    class="form-control input-white haut"
                                                                    name="label"
                                                                    v-model="fhost.value"
                                                                >
                                                                    <option
                                                                        v-for="i in itemsNums"
                                                                        :value="i.value"
                                                                        :key="i.value"
                                                                    >{{ i.label }}</option>

                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-3">
                                                            <div class="_min">
                                                                <input type="text"
                                                                    name="min"
                                                                    placeholder="Min"
                                                                    class="form-control input-white haut"
                                                                    v-model="fhost.min"
                                                                >
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-3">
                                                            <div class="_max">
                                                                <input type="text"
                                                                    name="max"
                                                                    placeholder="Max"
                                                                    class="form-control input-white haut"
                                                                    v-model="fhost.max"
                                                                >
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-2">
                                                            <button
                                                                class="btn btn-secondary"
                                                                @click.prevent="addFilterItem()"
                                                            >
                                                                <i class="feather icon-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-sm-12 text-right mt-20">
                                            <button
                                                class="btn btn-primary elevated mr-2"
                                                type="button"
                                                @click="loadFilters()"
                                            >
                                                Filtrer
                                            </button>
                                            <button
                                                class="btn btn-secondary elevated"
                                                type="button"
                                                @click.prevent="exportKeywords()"
                                            >
                                                Exporter
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <!-- End of filtre -->

                            <div class="row mt-40">
                                <div class="col-sm-7">
                                    <form @submit.prevent="applySelected('apply_for_tags')">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <IzyApplyCheckbox
                                                    :types="'all'"
                                                    :clicked="isClicked"
                                                    @allselected="allselected"
                                                    :text="'Tout sélectionner'"
                                                ></IzyApplyCheckbox>
                                            </div>

                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <label for="">Ajouter une étiquette à la sélection</label>
                                                    <div class="form-inline">
                                                        <input type="text"
                                                            name="tag"
                                                            placeholder=""
                                                            class="form-control input-white mr-2"
                                                            v-model="taghost.tag"
                                                            maxlength="30"
                                                        >
                                                        <button
                                                            :class=" isDisabled ? 'btn btn-primary disabled' : 'btn btn-primary'"
                                                            type="submit"
                                                        >
                                                            Appliquer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-5 float-right">
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <div class="form-group">
                                                <label for="">Ajouter les volumes de recherche pour la sélection</label><br>
                                                <button
                                                    :class=" isDisabled ? 'btn btn-primary disabled' : 'btn btn-primary'"
                                                    @click="confirmation_volume_apply()"
                                                    type="submit"
                                                >
                                                    Appliquer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="bando" v-show="checkValues">
                                        <div v-if="showFirst">
                                            <span>Les {{ selected.length }} mots-clés de cette page sont selectionnés. </span>
                                            <span
                                                class="span pointer bold"
                                                @click="apply('total')"
                                            > Sélectionner les {{ total_keywords }} mots-clés du filtre.</span>
                                        </div>
                                        <div v-else>
                                            <span>Les {{ total_keywords }} mots-clés du filtre sont sélectionnés.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="_overflow-table">
                                <table class="table table-striped table-verbatims">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col" class="text-left">Sélection</th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('token')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.token"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.token"></i>
                                                Mot Clé</th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('cluster')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.cluster"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.cluster"></i>
                                                Cluster</th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('pos')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.pos"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.pos"></i>
                                                POS</th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('occurrences')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.occurrences"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.occurrences"></i>
                                                Occurences</th>
                                            <th scope="col" class="text-left" >Etiquettes</th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('nb_verbatims')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.nb_verbatims"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.nb_verbatims"></i>
                                                Verbatims
                                            </th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('search_volume')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.search_volume"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.search_volume"></i>
                                                Volume
                                            </th>
                                            <th scope="col" class="text-left pointer" @click="setSortingKey('score')">
                                                <i class="feather icon-chevron-up" v-if="!sort_items.score"></i>
                                                <i class="feather icon-chevron-down" v-if="sort_items.score"></i>
                                                Score Sémantique
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="keyword in keywords" :key="keyword.uuid">
                                            <td>
                                                <IzyApplyCheckbox
                                                    id="meta_del_btn"
                                                    :value="keyword.uuid"
                                                    :selected="selected"
                                                    :text="''"
                                                    @changed="KeywordsChanged"
                                                >
                                                </IzyApplyCheckbox>
                                            </td>
                                            <td class="token">
                                                <!-- <i class="feather icon-eye-off"></i> -->
                                                {{ keyword.token }}
                                            </td>
                                            <td>{{ keyword.cluster ? keyword.cluster.name : ' - ' }}</td>
                                            <td>{{ keyword.pos ? keyword.pos : ' - ' }}</td>
                                            <td>{{ keyword.occurrences}}</td>
                                            <td class="tags">
                                                <i class="feather icon-plus" @click="selectTagModal(keyword)"></i>
                                                <ul class="list-inline" v-if="keyword.tags.length > 0">
                                                    <li
                                                        class="list-inline-item"
                                                        @click="remove(t,keyword)"
                                                        v-for="t in keyword.tags"
                                                        :key="t.uuid"
                                                        :id="`tag-${t.uuid}`"
                                                    >{{ t.tag }}</li>
                                                </ul>
                                            </td>
                                            <td class="verbatim color"><a @click="displayVerbatimsModal(keyword)">{{ keyword.nb_verbatims }}</a></td>
                                            <td>{{ keyword.search_volume>0 ? keyword.search_volume : '-' }}</td>
                                            <td>{{ keyword.score.toFixed(3) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mt-20 text-center">
                                <izyPaginate
                                    @nextPage="loadFilters"
                                    @previousPage="loadFilters"
                                    @currentPage="loadFilters"
                                    :showNextBtn="showNextBtn"
                                    :page="page"
                                ></izyPaginate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <DisplayVerbatimsModal
            :verbatimModalObject="verbatimModalObject"
            :projectModalUuid="projectModalUuid"
            @confirmed="voirVerbatimText"
        ></DisplayVerbatimsModal>

        <AddEtiquetteModal
            @confirmed="saveTags"
            :keyword="selectedKeyword"
            :page="page"
        ></AddEtiquetteModal>

        <SelectEtiquetteModal
            @confirmed="saveTags"
            @add="addTagModal"
            :tagsList="project_infos.tags"
            :keyword="selectedKeyword"
            :page="page"
        ></SelectEtiquetteModal>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selectedTag"
            :page="page"
            @confirmed="confirmRemoveEtiquette" />

        <ConfirmModal
            :id="'confirmVolumeModal'"
            title="Confirmation"
            resource="apply_for_volumes"
            :message="appliquerVolumeDescription"
            @confirmed="applySelected" />
    </div>
</template>

<script>
import SelectEtiquetteModal from './modals/select-etiquette-modal'
import AddEtiquetteModal from './modals/add-etiquette-modal'
import DisplayVerbatimsModal from './modals/display-verbatims-modal'
import FilterLine from './filtre/line'
import VueMultiselect from 'vue-multiselect'
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'Keywords',

    components: {
        SelectEtiquetteModal,
        AddEtiquetteModal,
        DisplayVerbatimsModal,
        VueMultiselect, FilterLine,
    },

    data: () => ({
        duration: '',
        endDate: '',
        showNextBtn: false,
        showFirst: true,
        show_x_keywords: true,
        msg: '',
        type: 'tags',
        type_btn: '',
        total_keywords: 0,
        total_verbatims: 0,
        page: 1,
        size: 30,
        isClicked: false,
        displayBandeau: false,
        taghost: { keywords: [], tag: '' },
        fhost: { value: '', min: '', max: '' },
        selectedTag: {},
        selectedKeyword: {},
        keyword: {},
        verbatim: {},
        selected: [],
        selectedCopy: [],
        selectedKeywordMask: [],
        keywords: [],
        keywordTags: [],
        itemsNums: [],
        itemsAdded: [],
        verbatimModalObject: {},
        projectModalUuid: {},
        text: 'init',
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer l\'étiquette ?',
        appliquerVolumeDescription: "Cette opération peut prendre quelques minutes. Voulez-vous vraiment ajouter les volumes de recherche à ces mots-clés ?",

        // Is this asc ?
        sort_items:{ 'score': true, 'token': true, 'pos': true, 'cluster': true, 'occurrences': true, 'search_volume': true, 'nb_verbatims': true },

        // Variables VueMultiselect
        value_corpus: [],
        keyword_value: [],
        verbatim_value: [],
        intervalIndex: null,
        interval: null
    }),

    beforeUnMount () {
        clearInterval(this.intervalIndex)
    },

    mounted () {
        this.loadProjectData()
        this.loadFilters()
        this.resetGhost()
        this.resetTaghost()
        this.initTableauFiltreNumerique()
        this.resetFhost()

        window.eventBus.$on('task', (result) => {
            let task_uuid = JSON.parse(localStorage.getItem('task_uuid'))
            if (result == 'state') {
                this.intervalIndex = setInterval(() => {
                    this.$store.dispatch('projects/getTaskStatus', task_uuid)
                }, 10000)
            }
        })

        window.eventBus.$on('end', (result) => {
            if (result == 'task') {
                clearInterval(this.intervalIndex)
                localStorage.removeItem('task_uuid')
                this.$store.commit('projects/SET_TASK', {})
                this.loadFilters()
            }
        })
    },

    watch: {
         progression: {
             immediate: true,
             handler: function (value) {
                 if ((value > 99) && (value <= 100)) {
                     this.endDate = moment().add(60, 's')
                     this.makeTimer()
                 }
             }
         },

         duration (val) {
             if (val <= 0) {
                 this.closeTimer()
                 return true
             }
         }
     },

    computed: {
        param () {
            return this.$router.currentRoute._value.params.id
        },

        project () {
            return this.$store.state.projects.project || {}
        },

        task () {
            return this.$store.state.projects.task || {}
        },

        progression () {
            return !_.isEmpty(this.task) ? this.task.progression : 0
        },

        project_infos () {
            return this.$store.state.projects.project_infos || {}
        },

        options () {
            return this.project_infos.tags || []
        },

        options_pos () {
            return this.project_infos ? this.project_infos.pos : []
        },

        p_corpus () {
            return this.$store.state.projects.project_corpus || []
        },

        y_restant () {
            let op = this.keywords.length-this.selected.length
            return op>0 ? op : 0
        },

        checkValues () {
            return this.size == this.selected.length
        },

        canDisplay () {
            return this.displayBandeau && this.checkValues
        },

        isDisabled () {
            return this.selected.length < 2
        }
     },

    methods: {
        closeTimer () {
            clearInterval(this.interval)
            this.duration  = '00:00:00'
            this.duration  = ''
            window.eventBus.$emit('end', 'task')
        },

        makeTimer () {
            this.interval = setInterval(() => {
                let duration = moment.duration(this.endDate.diff(moment(), true))
                let seconds = duration.get('seconds') > 9
                    ? duration.get('seconds')
                    : '0' + duration.get('seconds')

                this.duration = seconds
            }, 1000)
        },

        addCorpusTag (newTag) {
            const tag = {
                uuid: newTag.uuid,
                name: newTag.name
            }

            this.project_corpus.push(tag)
            this.value_corpus.push(tag)
        },

        initTableauFiltreNumerique () {
            this.itemsNums = [
                { value: "Longueur", label: "Longueur" },
                { value: "Occurences", label: "Occurences" },
                { value: "Verbatims", label: "#Verbatims" },
                { value: "Volume", label: "Volume" },
                { value: "Score", label: "Score" },
                { value: "Tags", label: "#Etiquettes" },
            ]
        },

        /**
        * Remove Item on Filter
        *
        * @return {Params}
        */
        removeFilterItem (item) {
            if (item.value == "Longueur") {
                delete this.ghost.min
                delete this.ghost.max
            } else if (item.value == "Occurences") {
                delete this.ghost.min_occurrences
                delete this.ghost.max_occurrences
            } else if (item.value == "Verbatims") {
                delete this.ghost.min_verbatims
                delete this.ghost.max_verbatims
            } else if (item.value == "Volume") {
                delete this.ghost.min_volume
                delete this.ghost.max_volume
            } else if (item.value == "Score") {
                delete this.ghost.min_score
                delete this.ghost.max_score
            } else if (item.value == "Tags") {
                delete this.ghost.min_tags
                delete this.ghost.max_tags
            }

            this.itemsAdded = this.itemsAdded.filter(i => i.value != item.value)
            let lbl = ""
            if (item.value == "Longueur") lbl = "Longueur"
            else if (item.value == "Occurences") lbl = "Occurences"
            else if (item.value == "Verbatims") lbl = "#Verbatims"
            else if (item.value == "Volume") lbl = "Volume"
            else if (item.value == "Score") lbl = "Score"
            else if (item.value == "Tags") lbl = "#Etiquettes"

            let itm = {
                value: item.value,
                label: lbl
            }

            this.itemsNums.push(itm)
            this.resetFhost()
        },

        /**
        * Add Item on Filter
        *
        * @return {Params}
        */
        addFilterItem () {
            let itm = {
                value: this.fhost.value,
                label: this.fhost.value,
                min: this.fhost.min == '' ? '' : this.fhost.min,
                max: this.fhost.max == '' ? '' : this.fhost.max,
                type: 'adding'
            }

            this.checkFilterHost(itm)
        },

        /**
        * Ckeck Filter Host
        *
        * @return {void}
        */
        checkFilterHost (objet) {
            if (objet.value != '' && (objet.min != '' || objet.max != '')) {
                if (objet.value == "Longueur") {
                    this.ghost.min = objet.min != '' ? parseInt(objet.min, 10) : ''
                    this.ghost.max = objet.max != '' ? parseInt(objet.max, 10) : ''
                } else if (objet.value == "Occurences") {
                    this.ghost.min_occurrences = objet.min != '' ? parseInt(objet.min, 10) : ''
                    this.ghost.max_occurrences = objet.max != '' ? parseInt(objet.max, 10) : ''
                } else if (objet.value == "Verbatims") {
                    this.ghost.min_verbatims = objet.min != '' ? parseInt(objet.min, 10) : ''
                    this.ghost.max_verbatims = objet.max != '' ? parseInt(objet.max, 10) : ''
                } else if (objet.value == "Volume") {
                    this.ghost.min_volume = objet.min != '' ? parseInt(objet.min, 10) : ''
                    this.ghost.max_volume = objet.max != '' ? parseInt(objet.max, 10) : ''
                } else if (objet.value == "Score") {
                    this.ghost.min_score = objet.min != '' ? parseFloat(objet.min) : ''
                    this.ghost.max_score = objet.max != '' ? parseFloat(objet.max) : ''
                } else if (objet.value == "Tags") {
                    this.ghost.min_tags = objet.min != '' ? parseInt(objet.min, 10) : ''
                    this.ghost.max_tags = objet.max != '' ? parseInt(objet.max, 10) : ''
                }
            }

            if (objet.value != '' && (objet.min != '' || objet.max != '') && objet.type == 'adding') {
                this.itemsAdded.push(objet)
                this.itemsNums = this.itemsNums.filter(i => i.value != objet.value)
                this.resetFhost()
            }
        },

        /**
        * edited
        *
        * @return {Params}
        */
        edited (itm) {
            let min = itm.min == '' ? 0 : itm.min
            let max = itm.max == '' ? 0 : itm.max

            if (itm.value == "Longueur") {
                this.ghost.min = parseInt(min, 10)
                this.ghost.max = parseInt(max, 10)
            } else if (itm.value == "Occurences") {
                this.ghost.min_occurrences = parseInt(min, 10)
                this.ghost.max_occurrences = parseInt(max, 10)
            } else if (itm.value == "Verbatims") {
                this.ghost.min_verbatims = parseInt(min, 10)
                this.ghost.max_verbatims = parseInt(max, 10)
            } else if (itm.value == "Volume") {
                this.ghost.min_volume = parseInt(min, 10)
                this.ghost.max_volume = parseInt(max, 10)
            } else if (itm.value == "Score") {
                this.ghost.min_score = parseFloat(min)
                this.ghost.max_score = parseFloat(max)
            } else if (itm.value == "Tags") {
                this.ghost.min_tags = parseInt(min, 10)
                this.ghost.max_tags = parseInt(max, 10)
            }

            this.itemsAdded = this.itemsAdded.filter(i => i.value != itm.value)
            this.itemsAdded.push(itm)
            this.resetFhost()
        },

        /**
        * Object of params
        *
        * @return {Params}
        */
        setParams (page=1) {
            let verbatim_tag = this.verbatim_value
            let keyword_tag = this.keyword_value

            let objet = {
                value: this.fhost.value,
                label: this.fhost.value,
                min: this.fhost.min == '' ? '' : this.fhost.min,
                max: this.fhost.max == '' ? '' : this.fhost.max,
                type: 'filtering'
            }
            this.checkFilterHost(objet)

            let payload = {
                project: this.param,
                corpus: this.value_corpus.map(c => c.uuid),
                verbatim_tag: verbatim_tag,
                keyword_tag: keyword_tag,
                pos: this.ghost.pos,
                entity: this.ghost.entity,
                cluster: this.ghost.cluster,
                query: this.ghost.query,
                sort_on: this.ghost.sort_on,
                sort_type: this.ghost.sort_type,
                new_tag: this.taghost.tag,
                min: this.ghost.min,
                max: this.ghost.max,
                min_occurrences: this.ghost.min_occurrences,
                max_occurrences: this.ghost.max_occurrences,
                min_verbatims: this.ghost.min_verbatims,
                max_verbatims: this.ghost.max_verbatims,
                min_score: this.ghost.min_score,
                max_score: this.ghost.max_score,
                min_volume: this.ghost.min_volume,
                max_volume: this.ghost.max_volume,
                min_tags: this.ghost.min_tags,
                max_tags: this.ghost.max_tags,
                uuids: this.ghost.uuids,
                page: page,
            }

            // Retirer un param quand il est null ou pas defini
            if (payload.corpus == undefined || payload.corpus == '') delete payload.corpus
            if (payload.verbatim_tag == undefined || payload.verbatim_tag == '') delete payload.verbatim_tag
            if (payload.keyword_tag == undefined || payload.keyword_tag == '') delete payload.keyword_tag
            if (payload.corpus == undefined || payload.corpus == '' || payload.corpus == null) delete payload.corpus
            if (payload.min == undefined || payload.min == '') delete payload.min
            if (payload.max == undefined || payload.max == '') delete payload.max
            if (payload.min_occurrences == undefined || payload.min_occurrences == '') delete payload.min_occurrences
            if (payload.max_occurrences == undefined || payload.max_occurrences == '') delete payload.max_occurrences
            if (payload.min_verbatims == undefined || payload.min_verbatims == '') delete payload.min_verbatims
            if (payload.max_verbatims == undefined || payload.max_verbatims == '') delete payload.max_verbatims
            if (payload.min_score == undefined || payload.min_score == '') delete payload.min_score
            if (payload.max_score == undefined || payload.max_score == '') delete payload.max_score
            if (payload.min_volume == undefined || payload.min_volume == '') delete payload.min_volume
            if (payload.max_volume == undefined || payload.max_volume == '') delete payload.max_volume
            if (payload.min_tags == undefined || payload.min_tags == '') delete payload.min_tags
            if (payload.max_tags == undefined || payload.max_tags == '') delete payload.max_tags
            if (payload.pos == undefined || payload.pos == '' || payload.pos == null) delete payload.pos
            if (payload.entity == undefined || payload.entity == '') delete payload.entity
            if (payload.cluster == undefined || payload.cluster == '') delete payload.cluster
            if (payload.min == undefined || payload.min == '') delete payload.min
            if (payload.max == undefined || payload.max == '') delete payload.max
            if (payload.query == undefined || payload.query == '') delete payload.query
            if (payload.sort_on == undefined || payload.sort_on == '') delete payload.sort_on
            if (payload.sort_type == undefined || payload.sort_type == '') delete payload.sort_type
            if (this.taghost.tag == undefined || this.taghost.tag == '') delete payload.new_tag
            if (payload.uuids == undefined || payload.uuids == null) delete payload.uuids
            if (payload.page == undefined || payload.page == null) delete payload.page

            return payload
        },

        apply (val) {
            this.type = val
            if (this.type == 'tags') {
                this.$toast.success(`Les ${this.selected.length} mots-clés de cette page ont été sélectionnés.`)
            } else {
                this.showFirst = false
                this.$toast.success(`Les ${this.total_keywords} mots-clés du filtre sont sélectionnés.`)
            }
        },

        applyTags () {
            if (this.taghost.tag != '') {
                this.type = 'tags'
                this.displayBandeau = true
                if (!this.canDisplay) {
                    this.saveApply()
                }
            } else {
                this.$swal.error('Validation', 'Saisissez la valeur de l\'étiquette à ajouter à la sélection')
            }
        },

        applyVolumes () {
            if (this.selected.length >= 2) {
                this.type = 'volumes'
                this.displayBandeau = true
                if (!this.canDisplay) {
                    this.saveApply()
                }
            } else {
                this.$swal.error('Validation', 'Sélectionnez au moins deux mots-clés pour appliquer le volume')
            }
        },

        applySelected (val) {
            this.type_btn = val

            if (this.type == 'tags' && this.type_btn == 'apply_for_tags') {
                if (this.taghost.tag != '') {
                    this.saveApply()
                } else {
                    this.$swal.error('Validation', 'Saisissez la valeur de l\'étiquette à ajouter à la sélection')
                }
            } else if (this.type == 'total' && this.type_btn == 'apply_for_tags') {
                if (this.taghost.tag != '') {
                    this.selectYKeywords()
                } else {
                    this.$swal.error('Validation', 'Saisissez la valeur de l\'étiquette à ajouter à la sélection')
                }
            } else if (this.type == 'tags' && this.type_btn == 'apply_for_volumes') {
                this.type = 'volumes'
                this.saveApply()
            } else if (this.type == 'total' && this.type_btn == 'apply_for_volumes') {
                this.type = 'volumes'
                this.selectYKeywords()
            } else if (this.type_btn == 'apply_for_volumes') {
                if (this.selected.length >= 2) {
                    this.type = 'volumes'
                    this.saveApply()
                } else {
                    this.$swal.error('Validation', 'Sélectionnez au moins deux mots-clés pour appliquer le volume')
                }
            } else if (this.type_btn == 'apply_for_tags') {
                if (this.taghost.tag != '') {
                    this.saveApply()
                } else {
                    this.$swal.error('Validation', 'Saisissez la valeur de l\'étiquette à ajouter à la sélection')
                }
            }
        },

        /**
        * Sélectionner les Y Mots-clés du filtre
        *
        * @return {void}
        */
        async selectYKeywords () {
            this.isLoading = true

            let url = ''
            let payload = this.setParams()
            let pload = {
                action: 'remove'
            }
            if (this.type == 'total') {
                url = 'api/keywords/filter/add/tag'
            }

            if (this.type == 'volumes') {
                url = 'api/keywords/filter/add/volumes'
            }

            const res = await this.$api.post(url, payload)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                    this.resetTaghost()
                    this.allselected(pload)
                })

            if (res) {
                this.isLoading = false
                if (this.type == 'tags') {
                    this.$swal.success('Confirmation', `Les ${this.total_keywords} mots-clés du filtre sont sélectionnés.`)
                }

                if (this.type == 'volumes') {
                    localStorage.setItem('task_uuid', JSON.stringify(res.data.task_id))
                    window.eventBus.$emit('task', 'state')
                    this.$swal.success('Confirmation', `Volume ajouté aux mots-clés.`)
                }

                this.loadFilters(this.page)
                this.loadProjectData()
                this.allselected(pload)
                this.displayBandeau = false
                this.isClicked = false
            }
        },

        async MasquerDemasquer (k) {
            this.text = 'mask'
            let uuid = k.uuid
            const res = await this.$api.post(`api/keywords/${uuid}/change/status`)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.$swal.success('Confirmation', res.data)
                this.loadFilters()
            }
        },

        async saveApply () {
            this.isLoading = true

            let url = ''
            let postData = {}
            let payload = {
                action: 'remove'
            }
            this.taghost.keywords = this.selectedCopy.map(f => f.uuid)
            if (this.type == 'tags') {
                url = 'api/keywords/tag/add'
                postData = {
                    "project": this.$router.currentRoute._value.params.id,
                    "keywords": this.taghost.keywords,
                    "tag": this.taghost.tag,
                }
            }

            if (this.type == 'volumes') {
                url = 'api/keywords/volumes/add'
                postData = {
                    "project": this.$router.currentRoute._value.params.id,
                    "keywords": this.taghost.keywords,
                }
            }

            const res = await this.$api.post(url, postData)
                .catch(error => {
                    console.log('error ', error.response.data)
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                    this.resetTaghost()
                    this.allselected(payload)
                })

            if (res) {
                if (this.type == 'volumes') {
                    localStorage.setItem('task_uuid', JSON.stringify(res.data.task_id))
                    window.eventBus.$emit('task', 'state')
                    this.$swal.success('Confirmation', 'Volume ajouté aux mots-clés')
                }

                if (this.type == 'tags') {
                    this.$swal.success('Confirmation', 'Ajout des mots-clés')
                }
                this.isLoading = false
                this.loadFilters(this.page)
                this.loadProjectData()

                this.allselected(payload)
                this.resetTaghost()
            }
        },

        resetTaghost () {
            this.taghost = {
                tag: '',
                keywords: []
            }

            this.displayBandeau = false
            this.isClicked = false
            this.type = 'tags'
            this.showFirst = true
            this.selectedCopy = []
            this.selected = []
        },

        resetFhost () {
            this.fhost = Object.assign({}, {
                value: this.itemsNums[0].value,
                min: '',
                max: '',
            })
        },

        KeywordsChanged (payload) {
            if (payload.action == 'add') {
                let temp = this.keywords.filter(f => f.uuid === payload.value)[0]
                this.selectedCopy.push(temp)
                return this.selected.push(payload.value)
            }

            this.selectedCopy = this.selectedCopy.filter(act => act.uuid !== payload.value)
            this.selected = this.selected.filter(act => act !== payload.value)
        },

        allselected (payload) {
            if (payload.action == 'add') {
                this.isClicked = true
                this.selectedCopy = this.keywords
                return this.selected = this.keywords.map(f => f.uuid)
            }

            if (payload.action == 'remove') {
                this.type = 'tags'
                this.showFirst = true
                this.resetTaghost()
            }
        },

        loadProjectData () {
            let param = this.$router.currentRoute._value.params.id
            this.$store.dispatch('projects/getProject', param)
            this.$store.dispatch('projects/getProjectCorpus', param)
            this.$store.dispatch('projects/getProjectInfos', param)
        },

        async exportKeywords() {
            this.isLoading = true

            // set params
            let payload = this.setParams()

            const create_export_res = await this.$api.post('api/keywords/create/export', payload )
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (create_export_res) {
                let get_payload = {
                    project: this.param,
                    temp_file: create_export_res.data.temp_file
                }
                const res = await this.$api.get('api/keywords/export', {params:get_payload, responseType:'blob'} )
                    .catch(error => {
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur', str)
                    })
                this.isLoading = false

                if (res) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "keywords_" + this.project.name + ".xlsx");
                    document.body.appendChild(link);
                    link.click();
                }
            }

        },

        async loadFilters (page) {
            this.isLoading = true

            // set params
            let payload = this.setParams(page)
            const res = await this.$api.post('api/keywords/filter', payload)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.keywords = res.data.keywords
                this.total_keywords = res.data.total
                this.size = res.data.size
                this.page = res.data.page
                this.total_verbatims = res.data.verbatims
                let lastPage = Math.ceil(res.data.total/res.data.size)
                this.showNextBtn = res.data.page+1 <= lastPage
                this.ghost.uuids = null
            }
        },

        resetGhost () {
            this.ghost = {
                project: this.param,
                corpus: '',
                verbatim_tag: '',
                keyword_tag: '',
                pos: '',
                entity: '',
                cluster: '',
                sort_on: '',
                sort_type: '',
            }
        },

        selectTagModal (k) {
            this.selectedKeyword = k
            this.$eventBus.$emit('selectKeywordTags', k.tags)
            window.$('#selectEtiquetteModal').modal('show')
        },

        addTagModal (k) {
            this.selectedKeyword = k
            this.$eventBus.$emit('addKeywordTag')
            window.$('#addEtiquettekModal').modal('show')
        },

        editTagModal (tag) {
            this.selectedTag = tag
            window.$('#editEtiquetteModal').modal('show')
        },

        displayVerbatimsModal (k) {
            if (k) {
                this.verbatimModalObject = k
                this.projectModalUuid = { project: this.param }
                window.$('#displayVerbatimsModal').modal('show')
            }
        },

        remove (tag, keyword) {
            this.selectedTag = {
                "tag": tag,
                "keyword": keyword
            }
            window.$('#confirmModal').modal('show')
        },

        confirmation_volume_apply () {
            window.$('#confirmVolumeModal').modal('show')
        },

        async confirmRemoveEtiquette (tag) {
            this.text = 'removetag'
            let page = JSON.parse(localStorage.getItem('current_page'))
            this.isLoading = true

            let formdata = new FormData()
            formdata.append('tag', tag.tag.tag)
            formdata.append('keyword', tag.keyword.uuid)
            formdata.append('project', this.$router.currentRoute._value.params.id)

            const res = await this.$api.post('api/keywords/remove/tag', formdata)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.ghost.uuids = this.keywords.map(k => k.uuid)
                this.loadFilters(page)
            }
        },

        async saveTags (payload) {
            this.isLoading = true

            let postData = {}
            if (payload.type == 'many') {
                postData = {
                    "project": this.$router.currentRoute._value.params.id,
                    "keyword": payload.keyword,
                    "tags": payload.tags,
                }
            } else {
                postData = {
                    "project": this.$router.currentRoute._value.params.id,
                    "keyword": payload.keyword,
                    "tags": [payload.tags],
                }
            }

            const res = await this.$api.post('api/keywords/add/tag', postData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.ghost.uuids = this.keywords.map(k => k.uuid)
                this.loadFilters(payload.page)
                this.loadProjectData()
            }
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            let page = JSON.parse(localStorage.getItem('current_page'))
            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadFilters(page)
        }
    }
}
</script>
