<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo pointer" @click="navigate({ name: 'Users' })">
                            <i class="feather icon-users"></i>
                            Utilisateurs
                        </div>
                    </div>

                    <div class="col-sm-6 text-right">
                        <div class="buttons">
                            <button
                                class="btn btn-secondary"
                                @click="goback()"
                                v-if="displayBack"
                            >
                                <i class="feather icon-arrow-left"></i>
                                Retour
                            </button>

                            <button
                                class="btn btn-secondary"
                                @click="go('Users')"
                                v-else
                            >
                                <i class="feather icon-arrow-left"></i>
                                Retour
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20" v-show="!isLoading">
            <h5 class="bold">
                Utilisateur : {{ user.first_name + ' ' + user.last_name }}
            </h5>
            <div class="row mt-20">
                <div class="col-sm-5">
                    <div class="bg-white pd-20 radius">
                        Email : {{ user.email }}
                        <br>Rôle : {{ role_name }}
                        <br>Date de création : {{ created_at(user.created_at) }}
                    </div>
                </div>

                <div class="col-sm-3">
                    <button
                        class="btn btn-primary btn-block"
                        @click="editUserModal()"
                        v-if="canModify"
                    >
                        Modifier
                    </button>

                    <button
                        class="btn btn-secondary btn-block"
                        @click="nettoyerActions()"
                        v-if="canModify"
                    >
                        Nettoyer les actions
                    </button>
                </div>
            </div>

            <div class="izy-table mt-20" v-if="user_actions.length > 0">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-4">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('created_at')"
                            >
                                Date
                                <i class="feather icon-chevron-up" v-if="!sort_items.created_at"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.created_at"></i>
                            </div>
                        </div>

                        <div class="col-8">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('name')"
                            >
                                Action
                                <i class="feather icon-chevron-up" v-if="!sort_items.name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.name"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div class="izy-table-row" v-for="action in user_actions" :key="action.uuid">
                        <div class="row">
                            <div class="col-4">
                                <div class="izy-table-col">{{ created_at(action.created_at) }}</div>
                            </div>

                            <div class="col-8">
                                <div class="izy-table-col">{{ action.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-20 text-center" v-if="user_actions.length > 0">
                <izyPaginate
                    @nextPage="loadUserActions"
                    @previousPage="loadUserActions"
                    @currentPage="loadUserActions"
                    :showNextBtn="showNextBtn"
                    :page="page"
                ></izyPaginate>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>
        <PasswordModal
            @confirmed="passwordConfirmed"
        />
        <editUserModal :roles="roles" :user="user" @confirmed="saveEdit"></editUserModal>
        <div></div>
    </div>
</template>

<script>
import AuthService from '@/services/auth'
import PasswordModal from './password-modal'
import editUserModal from './modals/edit-user-modal'

export default {
    name: 'User',
    components: { PasswordModal, editUserModal },

    data: () => ({
        user: {},
        page: 1,
        showNextBtn: false,
        user_actions: [],
        sort_items:{
            'created_at': true,
            'name': true
        },
    }),

    computed: {
        user_uuid () {
            return this.$router.currentRoute._value.params.id
        },

        role_name () {
            return this.user.role ? this.user.role.name : ''
        },

        roles () {
            return this.$store.state.roles.roles.filter(role => role.id !== 1)
        },

        auth () {
            return AuthService.user()
        },

        canModify () {
            return this.auth.account.role.id == 1 || this.auth.account.role.id == 2
        },

        displayBack () {
            return this.$router.options.history.state.back.split('/')[1] == 'account'
        }
    },

    mounted () {
        this.loadUser()
        this.loadUserActions()
        this.$store.dispatch('roles/getRoles')
    },

    methods: {
        /**
         * Nettoyer les actions d'un user
         *
         * @return {void}
         */
        async nettoyerActions () {
            this.isLoading = true

            const res = await this.$api.post(`/api/users/${this.user_uuid}/actions/cleanup`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.$swal.success('Confirmation', res.data)
                this.loadUserActions()
            }
        },

        /**
         * Save user's details
         *
         * @return {void}
         */
        showPasswordModal () {
            window.$('#passwordModal').modal('show')
        },

        /**
        * Open Edit User Modal
        *
        * @return {void}
        */
        editUserModal () {
            window.$('#editUserModal').modal('show')
        },


        /**
        * Save Update User
        *
        * @return {void}
        */
        async saveEdit (user) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('role', user.role)
            formData.append('email', user.email)
            formData.append('last_name', user.last_name)
            formData.append('first_name', user.first_name)

            const res = await this.$api.put(`/api/admin/users/${user.uuid}/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Modification effectuée avec succès')
                this.loadUser()
            }
        },

        /**
         * Save user's password
         *
         * @return {void}
         */
        async passwordConfirmed (payload) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('password', payload.password)
            formData.append('confirm_password', payload.confirm_password)

            const res = await this.$api.post(`api/admin/users/password/${this.user_uuid}/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.$swal.success('Confirmation', res.data)
            }
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadUserActions()
        },

        /**
        * Load User
        *
        * @return {void}
        */
        async loadUser () {
            this.isLoading = true

            const res = await this.$api.get(`api/admin/users/${this.user_uuid}/show`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.user = res.data
            }
        },

        /**
        * Load User Actions
        *
        * @return {void}
        */
        async loadUserActions (page = 1) {
            this.isLoading = true

            const payload = {
                "user": this.user_uuid,
                "page": page,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            const res = await this.$api.get(`api/users/actions`, { params: payload })
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    console.log('Error message ==> ', error.response.data)
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.user_actions = res.data.actions
                this.page = page
                this.showNextBtn = res.data.more
            }
        },
    }
}
</script>
