<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-paperclip"></i>
                            Corpus
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="text-right mt-10">
                            <button
                                class="btn btn-secondary mr-2"
                                @click="retour()"
                                :disabled="isLoading"
                            >
                                <i class="feather icon-arrow-left"></i>
                                Retour
                            </button>

                            <button
                                class="btn btn-primary"
                                @click="loadCorpusFiles()"
                                :disabled="isLoading"
                            >
                                <i class="feather icon-refresh-ccw"></i>
                                Actualiser
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">

            <div class="alert alert-success alert-dismissible fade show" role="alert">
                <i class="feather icon-alert-triangle mr-10"></i>
                Les projets qui utilisent déjà ce corpus ne seront pas touchés par cette modification.

                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>


            <div class="row">
                <div class="col-sm-5">
                    <div class="bg-white pd-20 radius">
                        <strong>{{ corpus.name }}</strong>
                        <br>Créé par : {{ corpus.creator }}
                        <br>Client : {{ corpus.client }}
                        <br>Date de création : {{ created_at(corpus.created_at) }}
                    </div>
                </div>

                <div class="col-sm-5" v-if="canDisplayDescription">
                    <div class="bg-white pd-20 radius description">
                        <strong>Description</strong> : {{ corpus.description }}
                    </div>
                </div>


                <div class="col-sm-2" v-if="isCreatedBy">
                    <button
                        class="btn btn-primary btn-block"
                        @click="editCorpusModal(corpus)"
                    >
                        <i class="feather icon-edit"></i> Modifier
                    </button>

                    <button
                        class="btn btn-secondary btn-block mt-10"
                        @click="uploadCorpusModal()"
                    >
                        <i class="feather icon-plus"></i> Importer
                    </button>
                </div>
            </div>

            <div class="izy-table mt-30" v-if="corpus_files.length > 0">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-3">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('name')"
                            >
                                Nom
                                <i class="feather icon-chevron-up" v-if="!sort_items.name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.name"></i>
                            </div>
                        </div>

                        <div class="col-2">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('created_at')"
                            >
                                Création
                                <i class="feather icon-chevron-up" v-if="!sort_items.created_at"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.created_at"></i>
                            </div>
                        </div>

                        <div class="col-3">
                            <div
                                class="izy-table-th pointer"
                            >
                                Importé par
                            </div>
                        </div>

                        <div class="col-2">
                            <div
                                class="izy-table-th"
                            >
                                Type
                            </div>
                        </div>
                        <div class="col-2">
                            <div
                                class="izy-table-th"
                            >
                                Exploitable
                            </div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div class="izy-table-row" v-for="file in corpus_files" :key="file.uuid">
                        <div class="row">
                            <div class="col-3">
                                <div class="izy-table-col">
                                    <i class="feather icon-trash-2 mr-2 pointer" @click="remove(file)" v-if="isCreatedBy"></i>
                                    <img :src="pdf" alt="" v-show="file.display_name.endsWith('pdf')">
                                    <img :src="pdf" alt="" v-show="file.display_name.endsWith('PDF')">
                                    <img :src="xlsx" alt="" v-show="file.display_name.endsWith('xlsx')">
                                    <img :src="xls" alt="" v-show="file.display_name.endsWith('xls')">
                                    <img :src="docx" alt="" v-show="file.display_name.endsWith('docx')">
                                    <img :src="doc" alt="" v-show="file.display_name.endsWith('doc')">
                                    <img :src="csv" alt="" v-show="file.display_name.endsWith('csv')">
                                    <img :src="odt" alt="" v-show="file.display_name.endsWith('odt')">
                                    {{ file.display_name }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">{{ created_at(file.created_at) }}</div>
                            </div>

                            <div class="col-3">
                                <div class="izy-table-col">{{ file.import_by }}</div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col success">{{ file.file_type }}</div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col"><ProgressBar :taux="file.processing_evolution"></ProgressBar></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-20 text-center" v-if="corpus_files.length > 0">
                <izyPaginate
                    @nextPage="loadCorpusFiles"
                    @previousPage="loadCorpusFiles"
                    @currentPage="loadCorpusFiles"
                    :showNextBtn="showNextBtn"
                    :page="page"
                ></izyPaginate>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>
        <EditCorpusModal :corpus="corpus" @confirmed="saveEdit"></EditCorpusModal>
        <UploadFilesModal :corpus="corpus" :types="types" @confirmed="saveUpload"></UploadFilesModal>
        <RecapUploadFilesModal
            :success="success"
            :errors="errors"
            :incorrect="incorrect"
            :existing="existing"
        ></RecapUploadFilesModal>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selected"
            @confirmed="confirmRemove" />

        <div></div>
    </div>
</template>

<script>
import EditCorpusModal from './modals/edit-modal'
import UploadFilesModal from './modals/upload-files'
import RecapUploadFilesModal from './modals/recap'
import ProgressBar from './components/progress'
import CorpusMixins from './mixins'
import AuthService from '@/services/auth'
import _ from 'lodash'

export default {
    name: 'CorpusShow',

    mixins: [CorpusMixins],

    data: () => ({
        corpus: {},
        selected: {},
        src: '',
        errors: [],
        incorrect: [],
        existing: [],
        success: 0,
        sort_items:{
            'name': true,
            'created_at': true,
            'type': true
        },
        page: 1,
        isCreatedBy: false,
        intervalIndex: null,
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer ce fichier ?'
    }),

    components: {
        EditCorpusModal, ProgressBar,
        UploadFilesModal, RecapUploadFilesModal,
    },

    computed: {
        uuid () {
            return this.$router.currentRoute._value.params.id
        },

        canDisplayDescription () {
            return !_.isEmpty(this.corpus.description) && this.corpus.description !== 'null'
        },

        types () {
            return this.$store.state.corpus.types
        },

        auth () {
            let user = AuthService.user()
            return user.account
        },

        user_uuid () {
            return this.auth.uuid
        },

        isAvailable () {
            return this.auth.role.role_code == 1 || this.auth.role.role_code == 2
        },

        corpus_files () {
            return this.$store.state.corpus.corpus_files
        },

        hasFalseItem () {
            return this.corpus_files.filter(cf => cf.processable == false)
        },

        showNextBtn () {
            return this.$store.state.corpus.showNext
        },

        clients () {
            return this.$store.state.clients.clients
        },

        displayedClients () {
            let tab = []
            if (this.auth.role.role_code == 3) {
                tab = this.clients.filter(c => c.created_by == this.user_uuid)
            }

            if (this.auth.role.role_code == 1 || this.auth.role.role_code == 2) {
                tab = this.clients
            }

            return tab
        },
    },

    watch: {
        hasFalseItem: {
            immediate: true,
            handler: function (val) {
                if (_.isEmpty(val)) {
                    window.eventBus.$emit('end', 'progression')
                }
            }
        }
    },

    mounted () {
        this.$store.dispatch('clients/getClients')
        this.$nextTick(() => {
            this.loadCorpus()
            this.loadCorpusFiles()
            this.$store.dispatch('corpus/getTypes')
        })

        window.eventBus.$on('files', (result) => {
            if (result == 'progress') {
                this.intervalIndex = setInterval(() => {
                    this.loadCorpusFilesOnStore()
                }, 30000)
            }
        })

        window.eventBus.$on('end', (result) => {
            if (result == 'progression') {
                clearInterval(this.intervalIndex)
            }
        })
    },

    methods: {
        retour () { this.go('Corpus') },

        isEditOrDel (corpus) {
            let isCreatedBy = this.sansTirets(this.user_uuid) == this.sansTirets(corpus.created_by)
            this.isCreatedBy = this.isAvailable || isCreatedBy
        },

        /**
        * Open Delete File Modal
        *
        * @return {void}
        */
        remove (file) {
            this.selected = file
            window.$('#confirmModal').modal('show')
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadCorpusFiles()
        },

        /**
        * Load Current Corpus
        *
        * @return {void}
        */
        async loadCorpus () {
            if (this.src !== 'save') {
                this.isLoading = true
            }

            const res = await this.$api.get(`api/corpus/${this.uuid}/show`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.corpus = res.data
                this.isEditOrDel(res.data)
                this.src = ''
            }
        },

        /**
        * Open Edit Corpus Modal
        *
        * @return {void}
        */
        editCorpusModal () {
            this.$eventBus.$emit('editCorpus', this.clients)
            window.$('#editCorpusModal').modal('show')
        },

        /**
        * Open Upload Corpus Modal
        *
        * @return {void}
        */
        uploadCorpusModal () {
            this.$eventBus.$emit('uploadFiles')
            window.$('#uploadFilesModal').modal('show')
        },

        /**
        * Save Update Corpus
        *
        * @return {void}
        */
        async saveEdit (corpus) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('name', corpus.name)
            formData.append('client', corpus.client_uuid)
            formData.append('description', corpus.description)

            const res = await this.$api.put(`/api/admin/corpus/${corpus.uuid}/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Modification effectuée avec succès')
                this.loadCorpus()
            }
        },

        /**
        * Save Upload Corpus Files
        *
        * @return {void}
        */
        async saveUpload (payload) {
            this.isLoading = true

            const res = await this.$api.post(`/api/admin/corpus/files/upload`, payload)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.errors = res.data.error_files
                this.incorrect = res.data.incorrect_type
                this.existing = res.data.existing_files
                this.success = res.data.success
                let num_errors = this.errors.length + this.incorrect.length + this.existing.length
                if (num_errors >= 1) {
                    window.$('#recapModal').modal('show')
                }
                window.eventBus.$emit('files', 'progress')
                this.loadCorpusFiles()
            }
        },

        loadCorpusFilesOnStore () {
            const payload = {
                "page": this.page,
                "corpus": this.uuid,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            let params = this.setParams(payload)
            this.$store.dispatch('corpus/getCorpusFiles', params)
        },

        setParams (pay) {
            let payload = pay
            if (payload.page == null || payload.page == undefined || payload.page == '') delete payload.page
            if (payload.corpus == null || payload.corpus == undefined || payload.corpus == '') delete payload.corpus
            if (payload.sort_on == null || payload.sort_on == undefined || payload.sort_on == '') delete payload.sort_on
            if (payload.sort_type == null || payload.sort_type == undefined || payload.sort_type == '') delete payload.sort_type

            return payload
        },

        /**
        * Load Corpus Files
        *
        * @return {void}
        */
        async loadCorpusFiles (page = 1) {
            this.isLoading = true

            const payload = {
                "page": page,
                "corpus": this.uuid,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            let url = `api/admin/corpus/${this.uuid}/files`
            const res = await this.$api.get(url, { params: payload })
                .catch(error => {
                    if (error.response.status == 500) {
                        clearInterval(this.intervalIndex)
                    }
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.$store.commit('corpus/SET_CORPUS_FILES', res.data.files)
                this.$store.commit('corpus/SET_SHOW_NEXT', res.data.more)
                this.page = page
            }
        },

        /**
        * Delete File Corpus
        *
        * @return {void}
        */
        async confirmRemove (file) {
            this.isLoading = true

            const payload = {
                "files": [file.uuid]
            }

            const res = await this.$api.post(`api/admin/corpus/files/remove`, payload)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Suppression effectuée avec succès')
                this.loadCorpusFiles()
            }
        },
    }
}
</script>
