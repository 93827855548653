<template>
    <div @click="check()">
        <div class="check">
            <i :class="`feather icon-${isChecked ? 'check-square' : 'square'}`"></i>
            {{ value }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'Checkbox',
    emits: ['changed'],

    props: {
        value: { type: [String, Number], default: '' },
        types: { type: String, default: '' },
        clicked: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },

    computed: {
        isChecked () {
            return !!this.selected.find(l => l === this.value)
        }
    },

    methods: {
        check () {
            this.$emit('changed', {
                action: this.isChecked ? 'remove' : 'add',
                value: this.value,
            })
        }
    }
}
</script>
