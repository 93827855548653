<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addLexiqueModal"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Ajouter un lexique</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="name"
                                    placeholder="Nom du lexique"
                                    class="form-control form-control-lg"
                                    v-model="ghost.name"
                                    required="required"
                                    maxlength="30"
                                >
                            </div>

                            <!-- TODO: AJouter Dropzone -->
                            <div class="dropzone" id="myDrop">
                                <div class="dz-default dz-message" data-dz-message="">
                                    <span>Déposer les fichiers ici pour les télécharger</span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >
                                  {{ 'Enregistrer' }}
                                </button>
                            </div>

                            <div class="col-6">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >
                                  {{ 'Annuler' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Dropzone from 'dropzone'

const DROPZONE_END_POINT = '/api/lexicons/files/import'
const DROPZONE_MAX_FILES = 1
const DROPZONE_MAX_FILE_SIZE = 2
const DROPZONE_THUMBNAIL_WIDTH = 150

export default {
    name: 'AddLexiqueModal',
    emits: ['confirmed', 'cancelled'],
    props: {
        project_uuid: { type: String, default: '' },
    },

    data: () => ({
        files: [],
        myDrop: {},
    }),

    mounted () {
        this.$eventBus.$on('addLexique', (result) => {
            if (!_.isEmpty(result)) {
                this.resetGhost()
            }
        })
        this.setupDropzone()
    },

    computed: {
        user () {
            return this.$store.state.user
        }
    },

    methods: {
        confirm () {
            if (this.files.length !== 0 && this.ghost.name != '') {
                this.ghost.project = this.project_uuid
                const payload = Object.assign({}, this.ghost, { file: _.cloneDeep(this.files) })
                this.$emit('confirmed', payload)
                this.resetGhost()
                this.myDrop.removeAllFiles()
                this.files = []
                this.close()
            } else {
                this.$swal.error('Validation', 'Selectionner le type de fichier et télécharger au moins un fichier')
            }
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.myDrop.removeAllFiles()
            this.resetGhost()
            this.deleteFiles()
            this.$emit('cancelled')
            this.close()
        },

        async deleteFiles () {
            if (this.files.length > 0) {
                this.isLoading = true

                let toDelete = _.cloneDeep(this.files)
                let files_uuid = []
                toDelete.forEach(function (file){
                    files_uuid.push(file)
                })

                let formData = new FormData()
                formData.append('file', files_uuid[0])

                const res = await this.$api.post('api/lexicons/files/delete', formData)
                    .catch(error => {
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur', str)
                        this.isLoading = false
                    })

                if (res) {
                    this.isLoading = false
                    this.files = []
                }
            }
        },

        resetGhost () {
            this.ghost = {
                name: '',
                project: this.project_uuid
            }
        },


        setupDropzone () {
            const _that = this
            Dropzone.autoDiscover = false

            window.$(document).ready(function () {
                _that.myDrop = new Dropzone('div#myDrop', {
                    url: _that.$api.baseUrl + DROPZONE_END_POINT,
                    thumbnailWidth: DROPZONE_THUMBNAIL_WIDTH,
                    maxFilesize: DROPZONE_MAX_FILE_SIZE,
                    maxFiles: DROPZONE_MAX_FILES,
                    acceptedFiles: `text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
                    headers: {
                        "X-Awesome-Header": "Analytics",
                        "Authorization": "Token " + _that.user.token
                    },

                    success: function (file, res) {
                        console.log("success", res);
                        _that.files.push(res)
                    },

                    error: function (file, error) {
                        _that.$swal.error('Erreur de téléchargement de fichier', error)
                    }
                })
            })
        }
    },

}
</script>
