<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="uploadFilesModal"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Importer fichiers</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <select
                                    class="form-control form-control-lg"
                                    name="file_type"
                                    v-model="ghost.file_type"
                                    required
                                >
                                        <option value="">Type de fichier</option>
                                    <option
                                        v-for="t in types"
                                        :key="t.uuid"
                                        :value="t.uuid"
                                    >{{ t.name }}</option>
                                </select>
                            </div>

                            <!-- TODO: AJouter Dropzone -->
                            <div class="dropzone" id="myDrop">
                                <div class="dz-default dz-message" data-dz-message="">
                                    <span>Déposer les fichiers ici pour les télécharger</span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >
                                  {{ 'Enregistrer' }}
                                </button>
                            </div>

                            <div class="col-6">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >
                                  {{ 'Annuler' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Dropzone from 'dropzone'

const DROPZONE_END_POINT = '/api/admin/files/import'
const DROPZONE_MAX_FILES = 25
const DROPZONE_MAX_FILE_SIZE = 25
const DROPZONE_THUMBNAIL_WIDTH = 150

export default {
    name: 'UploadFilesModal',
    emits: ['confirmed', 'cancelled'],
    props: {
        corpus: { type: Object, default: () => {}},
        types: { type: Array, default: () => []},
    },

    data: () => ({
        files: [],
        myDrop: {},
    }),

    mounted () {
        this.$eventBus.$on('uploadFiles', () => {
            this.resetGhost()
        })
        this.setupDropzone()
    },

    computed: {
        user () {
            return this.$store.state.user
        }
    },

    methods: {
        confirm () {
            if (this.ghost.file_type !== '' && this.files.length !== 0) {
                this.ghost.corpus = this.corpus.uuid
                const payload = Object.assign({}, this.ghost, { files: _.cloneDeep(this.files) })
                this.$emit('confirmed', payload)
                this.resetGhost()
                this.myDrop.removeAllFiles()
                this.files = []
                this.close()
            } else {
                this.$swal.error('Validation', 'Selectionnez le type de fichier et téléchargez au moins un fichier')
            }
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.myDrop.removeAllFiles()
            this.resetGhost()
            this.deleteFiles()
            this.files = []
            this.$emit('cancelled')
            this.close()
        },

        async deleteFiles () {
            this.isLoading = true

            let toDelete = _.cloneDeep(this.files)
            let files_uuid = []
            toDelete.forEach(function (file){
                files_uuid.push(file["file_uuid"])
            })
            let postData = postData = {
                "files": files_uuid,
            }

            const res = await this.$api.post('api/admin/files/delete', postData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
            }
        },

        resetGhost () {
            this.ghost = {
                file_type: '',
            }
        },


        setupDropzone () {
            const _that = this
            Dropzone.autoDiscover = false

            window.$(document).ready(function () {
                _that.myDrop = new Dropzone('div#myDrop', {
                    url: _that.$api.baseUrl + DROPZONE_END_POINT,
                    thumbnailWidth: DROPZONE_THUMBNAIL_WIDTH,
                    maxFilesize: DROPZONE_MAX_FILE_SIZE,
                    maxFiles: DROPZONE_MAX_FILES,
                    acceptedFiles: `application/pdf,text/csv,application/vnd.oasis.opendocument.text,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
                    headers: {
                        "X-Awesome-Header": "Analytics",
                        "Authorization": "Token " + _that.user.token
                    },

                    success: function (file, res) {
                        _that.files.push(res[0])
                    },

                    error: function (file, error) {
                        _that.$swal.error('Erreur de téléchargement de fichier', error)
                    }
                })
            })
        }
    },

}
</script>
