<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="editProjectModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Editer projet</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="name"
                                    placeholder="Nom du projet"
                                    class="form-control form-control-lg"
                                    maxlength="50"
                                    v-model="ghost.name"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <textarea
                                    placeholder="Description du projet"
                                    name="description" id="" rows="5"
                                    class="form-control"
                                    v-model="ghost.description"
                                ></textarea>
                            </div>
                        </form>


                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'ConfirmModal',
    emits: ['confirmed', 'cancelled'],

    computed: {
        project () {
            return JSON.parse(localStorage.getItem('project'))
        }
    },

    watch: {
        project: {
            immediate: true,
            handler: function (val) {
                if (!_.isEmpty(val)) {
                    this.ghost = Object.assign({}, val)
                }
            }
        }
    },

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.ghost = Object.assign({}, this.project)
            this.close()
        }
    }
}
</script>
