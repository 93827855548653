<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-user"></i>
                            Mon Compte
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">
            <div class="text-right">
                <button
                    class="btn btn-primary mr-3"
                    @click="showPasswordModal()"
                    :disabled="isLoading"
                >
                    Réinitialiser le mot de passe
                </button>

                <button
                    class="btn btn-secondary"
                    @click="displayActions()"
                    :disabled="isLoading"
                    v-if="isAvailable"
                >
                    Actions
                </button>
            </div>


            <div class="row mt-20">
                <div class="col-sm-3"></div>

                <div class="col-sm-6">
                    <div class="bg-white radius-lg bordered pd-20 pt-30 pb-30">
                        <form class="_form" @submit.prevent>
                            <div class="form-group">
                                <label for="lastname">Nom</label>
                                <input type="text"
                                    name="lastname"
                                    placeholder="Nom"
                                    class="form-control form-control-lg"
                                    :disabled="isLoading"
                                    required
                                    v-model="ghost.last_name"
                                >
                            </div>

                            <div class="form-group">
                                <label for="firstname">Prénom</label>
                                <input type="text"
                                    name="firstname"
                                    placeholder="Prénom"
                                    class="form-control form-control-lg"
                                    :disabled="isLoading"
                                    required
                                    v-model="ghost.first_name"
                                >
                            </div>

                            <div class="form-group">
                                <label for="firstname">Email</label>
                                <input type="email"
                                    name="email"
                                    placeholder="Email"
                                    class="form-control form-control-lg"
                                    :disabled="isLoading"
                                    required
                                    v-model="ghost.email"
                                >
                            </div>

                            <div class="mt-20 text-right">
                                <button
                                    class="btn btn-primary btn-lg btn-block"
                                    @click="save()"
                                    :disabled="isLoading"
                                >
                                    {{ isLoading ? 'Enregistrement en cours...' : 'Enregistrer'}}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <PasswordModal
            @confirmed="passwordConfirmed"
            :showOld="true"
        />
    </div>
</template>

<script>
import PasswordModal from './password-modal'
import AuthService from '@/services/auth'

export default {
    name: 'User',
    components: { PasswordModal },

    data: () => ({
        src: '',
        page: 1,
    }),

    mounted () {
        this.loadMe()
    },

    computed: {
        isAvailable () {
            return this.auth.account.role.id <= 2
        },

        auth () {
            return AuthService.user()
        },
    },

    methods: {
        /**
         * Save user's details
         *
         * @return {void}
         */
        async save () {
            this.isLoading = true

            let formData = new FormData()
            formData.append('email', this.ghost.email)
            formData.append('last_name', this.ghost.last_name)
            formData.append('first_name', this.ghost.first_name)

            const res = await this.$api.put(`/api/admin/users/${this.ghost.uuid}/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Modification effectuée avec succès')
                this.loadMe()
            }
        },

        /**
        * Navigate to user logs
        *
        * @return {void}
        */
        displayActions () {
            let user = this.ghost
            this.navigate({
                name: 'User', params: { id: user.uuid }
            })
        },

        /**
         * show Password Modal
         *
         * @return {void}
         */
        showPasswordModal () {
            window.$('#passwordModal').modal('show')
        },

        /**
         * Save user's password
         *
         * @return {void}
         */
        async passwordConfirmed (payload) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('current_password', payload.current_password)
            formData.append('password', payload.password)
            formData.append('confirm_password', payload.confirm_password)

            const res = await this.$api.post(`/api/users/my/password/update`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.$swal.success('Confirmation', 'Mot de passe modifié')
            }
        },

        /**
         * Load Current Account
         *
         * @return {void}
         */
        async loadMe () {
            if (this.src == '') {
                this.isLoading = true
            }

            const res = await this.$api.get(`api/users/me`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.ghost = res.data
            }
        },
    }
}
</script>
