<template>
    <div id="project-wrapper" class="toggled">
        <Sidebar />

        <div id="page-content-wrapper" v-show="!isLoading">
            <div class="page-content-header pointer" @click="loadFilters()">
                <h4>{{ project.name }}</h4>
            </div>

            <div class="container-fluid">
                <div class="page-content">
                    <div class="verbatims mt-40">
                      <div class="_hsearch">
                        <div class="search_form">
                            <form @submit.prevent="loadFilters()" class="_form">
                                <div class="form-group form-group-lg">
                                    <div class="inner-addon left-addon">
                                        <i class="glyphicon feather icon-search"></i>

                                        <input type="text"
                                            name="keywords"
                                            placeholder="Saisir le nom d'un lexique"
                                            autocomplete="off"
                                            class="form-control"
                                            v-model="ghost.keywords"
                                        >
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="add_btn text-right">
                          <button
                              class="btn btn-primary mb-10"
                              @click="addLexiqueModal()"
                              :disabled="isLoading"
                          >
                              Nouveau lexique
                          </button>
                        </div>
                      </div>

                      <!-- <div class="_overflow-table"> -->
                        <table class="table table-striped table-verbatims">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col" class="text-left">Nom</th>
                                    <th scope="col" class="text-left">Creation</th>
                                    <th scope="col" class="text-left">#Mots-Clés</th>
                                    <th scope="col" class="text-left">Stats Projet</th>
                                    <th scope="col" class="text-left">Stats KWFinder</th>
                                    <th scope="col" class="text-left">Stats Positions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="lex in lexiques" :key="lex.uuid">
                                    <td :class="processing ? 'avoid-clicks' : 'pointer'">
                                        <i class="feather icon-trash-2 mr-2 pointer" @click="remove(lex)"></i>
                                        <span @click="editModal(lex)">{{ lex.name }}</span>
                                    </td>
                                    <td>{{ created_at(lex.created_at) }}</td>
                                    <td>{{ lex.nb_keywords }}</td>
                                    <td @click="exportStatsProject(lex)" class="pointer">
                                      <i class="feather icon-download gros"></i></td>
                                    <td @click="exportStatsKWFinder(lex)" class="pointer">
                                      <i class="feather icon-download gros"></i></td>
                                    <td @click="exportStatsPos(lex)" :class="processing ? 'avoid-clicks' : 'pointer'">
                                      <i class="feather icon-download gros"></i></td>
                                </tr>
                            </tbody>
                        </table>
                      <!-- </div> -->

                      <div class="mt-20 text-center">
                          <izyPaginate
                              @nextPage="loadFilters"
                              @previousPage="loadFilters"
                              @currentPage="loadFilters"
                              :showNextBtn="showNextBtn"
                              :page="page"
                          ></izyPaginate>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>
        <addLexiqueModal @confirmed="saveLexique" :project_uuid="param"></addLexiqueModal>
        <editLexiqueModal @confirmed="saveEdit" :lexique="selectedLexique"></editLexiqueModal>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selectedLexique"
            :page="page"
            @confirmed="confirmRemove" />
    </div>
</template>

<script>
import addLexiqueModal from './modals/add-lexique'
import editLexiqueModal from './modals/edit-lexique'
// import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'Lexiques',

    components: {
        addLexiqueModal,
        editLexiqueModal
    },

    data: () => ({
        duration: '',
        endDate: '',
        lexiques: [],
        selectedLexique: {},
        intervalIndex: null,
        interval: null,
        temp_file: '',
        page: 1,
        showNextBtn: false,
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer ce lexique ?',
    }),

    mounted () {
        this.loadFilters()
        this.loadProjectData()

        window.eventBus.$on('ended', (result) => {
            if (result == 'task') {
                this.$store.commit('SET_PROCESSING', false)
                this.$store.commit('projects/SET_TASK', {})
                this.downloadPosFile()
            }
        })
    },

    computed: {
        param () {
            return this.$router.currentRoute._value.params.id
        },

        project () {
            return this.$store.state.projects.project || {}
        },

        processing () {
            return this.$store.state.processing
        },
     },

    methods: {
        async exportStatsProject (lexique) {
            this.isLoading = true

            const create_export_res = await this.$api.post(`api/lexicons/${lexique.uuid}/project/stats/create/export`)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur create export', str)
                    this.isLoading = false
                })

            if (create_export_res) {
                let get_payload = {
                    temp_file: create_export_res.data.temp_file
                }

                let url = `api/lexicons/${lexique.uuid}/project/stats/export`
                const res = await this.$api.get(url, { params:get_payload, responseType:'blob' } )
                    .catch(error => {
                        this.isLoading = false
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur stats export', str)
                    })

                if (res) {
                    const Url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = Url
                    link.setAttribute('download', "lexique_project_stats_" + lexique.name + ".xlsx")
                    document.body.appendChild(link)
                    link.click()
                    this.isLoading = false
                }
            }
        },

        async exportStatsKWFinder (lexique) {
            this.isLoading = true

            const create_export_res = await this.$api.post(`api/lexicons/${lexique.uuid}/project/kwfinder/create/export`)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur create export', str)
                    this.isLoading = false
                })

            if (create_export_res) {
                let get_payload = {
                    temp_file: create_export_res.data.temp_file
                }

                let url = `api/lexicons/${lexique.uuid}/project/kwfinder/export`
                const res = await this.$api.get(url, { params:get_payload, responseType:'blob' } )
                    .catch(error => {
                        this.isLoading = false
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur stats export', str)
                    })
                if (res) {
                    const Url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = Url
                    link.setAttribute('download', "lexique_project_kwfinder_" + lexique.name + ".xlsx")
                    document.body.appendChild(link)
                    link.click()
                    this.isLoading = false
                }
            }
        },

        async exportStatsPos (lexique) {
            this.isLoading = true
            this.$store.commit('SET_PROCESSING', true)

            localStorage.setItem('lexique', JSON.stringify(lexique))

            const res = await this.$api.post(`api/lexicons/${lexique.uuid}/project/position/create/export`)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur create export', str)
                    this.isLoading = false
                    this.$store.commit('SET_PROCESSING', false)
                })

            if (!_.isEmpty(res.data)) {
                let data = res.data
                this.isLoading = false

                if ('task_id' in data){
                    localStorage.setItem('task_uuid', JSON.stringify(data.task_id))
                    window.eventBus.$emit('task', 'state')
                }

                if ('temp_file' in data) {
                    this.downloadPosFile()
                }
            }
        },

        async downloadPosFile () {
            this.isLoading = true
            let lexique = JSON.parse(localStorage.getItem('lexique'))
            let get_payload = {
                temp_file: lexique.uuid
            }

            let url = `api/lexicons/${lexique.uuid}/project/position/export`
            const res = await this.$api.get(url, { params:get_payload, responseType:'blob' } )
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur stats export', str)
                })
            if (res) {
                this.isLoading = false
                const Url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = Url
                link.setAttribute('download', "lexique_project_stats_positions_" + lexique.name + ".xlsx")
                document.body.appendChild(link)
                link.click()
                localStorage.removeItem('lexique')
                this.$store.commit('SET_PROCESSING', false)
                this.loadFilters()
                // document.location.reload(true)
            }
        },

        loadProjectData () {
            let param = this.$router.currentRoute._value.params.id
            this.$store.dispatch('projects/getProject', param)
            this.$store.dispatch('projects/getProjectCorpus', param)
            this.$store.dispatch('projects/getProjectInfos', param)
        },

        async loadFilters () {
            this.isLoading = true

            // set params
            let payload = {
                name: this.ghost.keywords,
                project: this.param
            }
            const res = await this.$api.get('api/lexicons/list', { params: payload })
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.processing = false
                this.lexiques = res.data.lexicons
                this.showNextBtn = res.data.more
            }
        },

        async saveLexique (params) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('name', params.name)
            formData.append('file', params.file)
            formData.append('project', params.project)

            const res = await this.$api.post('api/lexicons/store', formData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.$swal.success('Confirmation', 'Lexique ajouté avec succès')
                this.loadFilters()
            }
        },

        async saveEdit (params) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('name', params.name)

            const res = await this.$api.put(`api/lexicons/${params.uuid}/update`, formData)
                .catch(error => {
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                    this.isLoading = false
                })

            if (res) {
                this.isLoading = false
                this.$swal.error('Confirmation', 'Lexique modifié avec succès !')
                this.loadFilters()
            }
        },

        /**
        * Set sorting key
        *
        * @return {void}
        */
        setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            let page = JSON.parse(localStorage.getItem('current_page'))
            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadFilters(page)
        },

        /**
        * Open Add Lexique Modal
        *
        * @return {void}
        */
        addLexiqueModal () {
            this.$eventBus.$emit('addLexique', this.param)
            window.$('#addLexiqueModal').modal('show')
        },

        /**
        * Open Edit Lexique Modal
        *
        * @return {void}
        */
        editModal (lexique) {
            this.$eventBus.$emit('editLexique', lexique)
            this.selectedLexique = lexique
            window.$('#editLexiqueModal').modal('show')
        },

        /**
        * Open Delete Lexique Modal
        *
        * @return {void}
        */
        remove (lexique) {
            this.selectedLexique = lexique
            window.$('#confirmModal').modal('show')
        },

        /**
        * Delete Lexique
        *
        * @return {void}
        */
        async confirmRemove (lexique) {
            this.isLoading = true

            let uuid = lexique.uuid.replace('-','')
            const res = await this.$api.delete(`/api/lexicons/${uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.$swal.success('Confirmation', 'Suppression effectuée avec succès')
                this.loadFilters()
            }
        }
    }
}
</script>
