import axios  from 'axios'
import env from '../../env'
import AuthService from './auth'

axios.defaults.baseURL = env.apiUrl
if (localStorage.getItem(env.token) !=  null){
    axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem(env.token)
}
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'

// Intercept all 401
axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        AuthService.logout()
        this.$router.push({ name: 'Signin' })
    }
    return Promise.reject(error)
})

export default {
    baseUrl: env.apiUrl,

    get (url, params) {
        return axios.get(url, params)
    },

    post (url, payload) {
        return axios.post(url, payload)
    },

    postWithHeader (url, payload, headers) {
        return axios.post(url, payload, headers)
    },

    put (url, payload) {
        return axios.put(url, payload)
    },

    patch (url, payload) {
        return axios.patch(url, payload)
    },

    delete (url, params) {
        return axios.delete(url, params)
    },

    setToken (token) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`
    },

    clearToken () {
        axios.defaults.headers.common['Authorization'] = ''
    }
}
