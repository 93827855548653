<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addClientModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Nouveau client</h4>


                    <div class="form-container">
                        <form class="_form mt-40" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="name"
                                    placeholder="Nom du client"
                                    class="form-control form-control-lg"
                                    v-model="ghost.name"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <textarea
                                    placeholder="Description du client"
                                    name="description" id="" rows="5"
                                    class="form-control"
                                    v-model="ghost.description"
                                ></textarea>
                            </div>
                        </form>

                    </div>

                    <div class="mt-40 text-center">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary float-left"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'addClientModal',
    emits: ['confirmed', 'cancelled'],

    mounted () {
        this.$eventBus.$on('addClient', () => {
            this.ghost.name = ''
            this.ghost.description = ''
        })
    },

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        },
    }
}
</script>
