<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="voirTextModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Verbatim</h4>

                    <div class="voir-text" v-html="text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'voirTextModal',

    props: {
        text: {
            type: String,
            default: 'Texte vide'
        }
    }
}
</script>
