<template>
    <div id="project-wrapper" class="toggled">
        <Sidebar />

        <div id="page-content-wrapper"  v-show="!isLoading">
            <div class="page-content-header">
                <div class="row">
                    <div class="col-6">
                        <h4>{{ name }}</h4>
                    </div>

                    <div class="col-6 text-right">
                        <button
                            class="btn btn-secondary"
                            @click="go('Projects')"
                        >
                            <i class="feather icon-arrow-left"></i>
                            Retour
                        </button>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="page-content">
                    <div class="text-right mt-10">
                        <IzyProgress
                            :taux="progress"
                        ></IzyProgress>
                    </div>

                    <div class="mt-20 row">
                        <div class="col-sm-4">
                            <img :src="Placeholder" class="img-fluid">
                        </div>

                        <div class="col-sm-4">
                            <div class="project-content">
                                <table class="table borderless">
                                    <tbody>
                                        <tr>
                                            <td class="text-right">Client:</td>
                                            <td class="text-left bold">{{ client_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Statut:</td>
                                            <td class="text-left bold">{{ project.status }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Création:</td>
                                            <td class="text-left bold">{{ createdAt(project.created_at) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Fichiers:</td>
                                            <td class="text-left bold">{{ project.nb_files }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Verbatims:</td>
                                            <td class="text-left bold">{{ project.nb_verbatims }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Mots-clés:</td>
                                            <td class="text-left bold">{{ project.nb_keywords }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Clusters:</td>
                                            <td class="text-left bold">{{ project.nb_clusters }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-sm-4" v-if="project.description">
                            <div class="project-content description">
                                <p v-html="project.description"></p>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-40">
                        <div class="col-4 text-left"></div>

                        <div class="col-8 text-right mb-20">
                            <div class="buttons">
                                <button
                                    class="btn btn-danger btn-lg mr-10" v-if="canDel"
                                    @click="confirm"
                                >
                                    <i class="feather icon-trash-2"></i>
                                    Supprimer projet
                                </button>

                                <button
                                    class="btn btn-primary btn-lg"
                                    @click="editModal"
                                >
                                    <i class="feather icon-edit"></i>
                                    Editer projet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <EditProjectModal
            @confirmed="saveEdit"
        ></EditProjectModal>

        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="project"
            @confirmed="confirmRemove" />
    </div>
</template>

<script>
import Placeholder from '@/assets/images/placeholder.png'
import EditProjectModal from './modals/edit-project-modal'
import moment from 'moment'
import _ from 'lodash'
import AuthService from '@/services/auth'

export default {
    name: 'ProjectShow',
    components: { EditProjectModal },

    data: () => ({
        Placeholder,
        intervalIndex: null,
        canShow: false,
        progress: {},
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer ce projet ?',
        progressCountDown: null
    }),

    created () {
        this.$store.dispatch('projects/getProject', this.$route.params.id)
        this.$store.dispatch('projects/getProjectInfos', this.$route.params.id)
        this.checkProjectProgress()
        this.resetProgressCountDown()
    },

    beforeUnMount () {
        clearInterval(this.intervalIndex)
        clearTimeout(this.progressCountDown)
    },

    computed: {
        project () {
            return this.$store.state.projects.project
        },

        name () {
            return this.project ? this.project.name : ''
        },

        client_name () {
            return this.project.client ? this.project.client.name : ''
        },

        taux () {
            return this.$store.state.projects.taux || {}
        },

        tauxStatus () {
            return !_.isEmpty(this.taux) ? this.taux.status : ''
        },

        isCurrentRoute () {
            return this.$router.currentRoute._value.name == 'Project'
        },

        auth () {
            let user = AuthService.user()
            return user.account
        },

        user_uuid () {
            return this.auth.uuid.replace('-', '')
        },

        isCreatedBy () {
            return this.sansTirets(this.project.created_by) == this.user_uuid
        },

        isAvailable () {
            return this.auth.role.role_code == 1 || this.auth.role.role_code == 2
        },

        canDel () {
            return this.isCreatedBy || this.isAvailable
        }
    },

    methods: {
        createdAt (date) { return moment(date).format('DD/MM/YYYY') },

        async confirmRemove (project) {
            this.isLoading = true

            const response = await this.$api.delete(`api/admin/projects/${project.uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (response) {
                this.isLoading = false
                this.$store.dispatch('projects/getProjects')
                this.navigate({ name: 'Projects' })
            }
        },

        confirm () { window.$('#confirmModal').modal('show') },

        editModal () { window.$('#editProjectModal').modal('show') },

        /**
         * Save project edit
         *
         * @return {void}
         */
        async saveEdit (project) {
            this.isLoading = true
            let formdata = new FormData()

            formdata.append('name', project.name)
            formdata.append('description', project.description)

            const response = await this.$api.put(`api/admin/projects/${project.uuid}/update`, formdata)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })
            if (response) {
                this.$store.commit('projects/SET_PROJECT', response.data)
                this.isLoading = false
            }
        },

        /**
         * Check project progress
         */
        async checkProjectProgress () {
            let param = this.$route.params.id
            const res = await this.$api.get(`api/projects/processing/${param}/state`)
                .catch(error => {
                    if (error.response.status == 500) {
                        clearInterval(this.intervalIndex)
                        clearTimeout(this.progressCountDown)
                    }
                })

            if (res) {
                if (res.data.progression !== this.progress.progression)
                    this.resetProgressCountDown()

                this.progress = res.data
                clearInterval(this.intervalIndex)

                if (this.progress.status !== 'Complete') {
                    this.intervalIndex = setInterval(() => {
                        this.checkProjectProgress()
                    }, 60000)
                } else {
                    clearInterval(this.intervalIndex)
                    clearTimeout(this.progressCountDown)
                }
            }
        },

        /**
         * Reset progress countdown
         *
         * @return {void}
         */
        resetProgressCountDown () {
            clearTimeout(this.progressCountDown)

            this.progressCountDown = setTimeout(() => {
                clearInterval(this.intervalIndex)
            }, 10 * 60 * 1000)
        }
    }
}
</script>
