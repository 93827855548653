/* eslint no-unused-vars: "off" */
import ApiService from '@/services/api'

export default {
    namespaced: true,

    state: {
        showNext: false,
        types: [],
        all_corpus: [],
        corpus_files: []
    },

    mutations: {
        SET_TYPES (state, payload) {
            state.types = payload
        },

        SET_ALL_CORPUS (state, payload) {
            state.all_corpus = payload
        },

        SET_CORPUS_FILES (state, payload) {
            state.corpus_files = payload
        },

        SET_SHOW_NEXT (state, value) {
            state.showNext = value
        },
    },

    actions: {
        async getAllCorpus ({ commit }) {
            const response = await ApiService.get(`api/corpus/all`)
                .catch(error => console.log(error))

            commit('SET_ALL_CORPUS', response.data.files)
        },

        async getCorpusFiles ({ commit }, payload) {
            const response = await ApiService.get(`api/admin/corpus/${payload.corpus}/files`, { params: payload })
                .catch(error => console.log(error))

            commit('SET_CORPUS_FILES', response.data.files)
            commit('SET_SHOW_NEXT', response.data.more)
        },

        async getTypes ({ commit }) {
            const response = await ApiService.get('api/corpus/files/types')
                .catch(error => console.log(error))

            commit('SET_TYPES', response.data)
        }
    },

    getters: {
        getAllCorpus: state => state.all_corpus,
        getTypes: state => state.types,
    }
}
