<template lang="html">
    <div class="num-items">
        <div class="row num-item" v-for="tab in tab_items" :key="tab.value">
            <div class="col-5 mt-10">
                <div class="form-group">
                    <select
                        class="form-control input-white"
                        name="label"
                        v-model="ghost.label_item"
                    >
                        <option
                            v-for="i in items"
                            :value="i.value"
                            :key="i.value"
                        >{{ i.label }}</option>

                    </select>
                </div>
            </div>

            <div class="col-3 mt-10">
                <div class="form-group">
                    <input type="text"
                        name="min"
                        placeholder="Min"
                        class="form-control input-white"
                        v-model="ghost.min"
                    >
                </div>
            </div>

            <div class="col-3 mt-10">
                <div class="form-group">
                    <input type="text"
                        name="max"
                        placeholder="Max"
                        class="form-control input-white"
                        v-model="ghost.max"
                    >
                </div>
            </div>
            <div class="col-1 mt-10" @click="del()">
                <div class="del-item-num">
                    <i class="feather icon-x"></i>
                </div>
            </div>
        </div>
        <div class="col-12 right" @click="add()">
            <div class="add-item-num">
                <i class="feather icon-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'FiltreNumerique',

    data: () => ({
        name_min: '',
        name_max: '',
        ghost_min: '',
        ghost_max: '',
        tab_items: [],
        item: {}
    }),

    props: {
        items: { type: Array, default: () => [] },
    },

    watch: {
        'ghost.label_item' (val) {
            if (val) {
                let data = this.items.filter(i => i.value === val)[0]
                if (data.value === 'keywords') {
                    this.name_min = 'min_keywords'
                    this.name_max = 'max_keywords'
                }
                else if (data.value === 'occurences') {
                    this.name_min = 'min_occurences'
                    this.name_max = 'max_occurences'
                }
                else if (data.value === 'verbatims') {
                    this.name_min = 'min_verbatims'
                    this.name_max = 'max_verbatims'
                }
                else if (data.value === 'volume') {
                    this.name_min = 'min_volume'
                    this.name_max = 'max_volume'
                }
                else if (data.value === 'score') {
                    this.name_min = 'min_score'
                    this.name_max = 'max_score'
                }
                else if (data.value === 'tags') {
                    this.name_min = 'min_tags'
                    this.name_max = 'max_tags'
                }
            }
        },

        items (val) {
            if (!_.isEmpty(val)) {
                this.tab_items.push(val[0])
            }
        },

    },

    methods: {
      del () {
          this.tab_items.shift()
      },

      add () {
         this.tab_items.push(this.items[0])
      }
    }
}
</script>
