<template>
    <div @click="check()">
        <div class="check" v-if="types == 'all'">
            <i :class="`feather icon-${clicked ? 'check-square' : 'square'}`"></i> {{ text }}
        </div>
        <div class="check" v-else>
            <i :class="`feather icon-${isChecked ? 'check-square' : 'square'}`"></i> {{ text }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'apply-checkbox',
    emits: ['changed', 'allselected'],

    props: {
        value: { type: [String, Number], default: '' },
        types: { type: String, default: '' },
        clicked: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
        text: { type: String, default: 'Tout selectionner'},
    },

    computed: {
        isChecked () {
            return !!this.selected.find(l => l === this.value)
        }
    },

    methods: {
        check () {
            if (this.types == 'all') {
                this.$emit('allselected', {
                    action: this.clicked ? 'remove' : 'add'
                })
            } else {
                this.$emit('changed', {
                    action: this.isChecked ? 'remove' : 'add',
                    value: this.value,
                })
            }
        }
    }
}
</script>
