<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-settings"></i>
                            Paramètres
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">

            <div class="settings" v-show="!isLoading">
                <div class="gauche">
                    <h4>Google Search Console</h4>
                    <p>- Concerne Corpus | Google API Keywords</p>
                    <p>- Concerne Projet X | Lexiques | Stats Positions</p>

                    <div class="form-group">
                        <label for="">Nombre de résultats</label>
                        <select
                            class="form-control form-control-lg"
                            name="search_console_nb_result"
                            v-model="ghost.search_console_nb_result"
                            required
                        >
                            <option
                                v-for="nb in nbs"
                                :key="nb"
                                :value="nb"
                            >{{ nb }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="">Localisation</label>
                        <input
                            type="text"
                            class="form-control form-control-lg"
                            name="search_console_location"
                            v-model="ghost.search_console_location"
                            required
                        >
                    </div>
                </div>

                <div class="droite">
                    <h4>KWFinder</h4>
                    <p>- Concerne Projet X | Mots clés | Volume E</p>
                    <p>- Concerne Projet X | Lexiques | Stats KWFinder</p>

                    <div class="form-group">
                        <label for="">Localisation</label>
                        <input
                            type="text"
                            class="form-control form-control-lg"
                            name="kwfinder_location"
                            v-model="ghost.kwfinder_location"
                            required
                        >
                    </div>
                </div>
            </div>

            <div class="mt-20 text-right">
                <button
                    class="btn btn-primary btn-lg mr-3"
                    @click="save()"
                    v-show="!isLoading"
                >
                    {{ isLoading ? 'Enregistrement en cours...' : 'Enregistrer'}}
                </button>

                <button
                    class="btn btn-secondary btn-lg"
                    @click="reset()"
                    v-show="!isLoading"
                >
                    {{ 'Annuler' }}
                </button>


            </div>

            <Loaders v-show="isLoading"></Loaders>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Settings',

    data: () => ({
        setting: {},
        nbs: [10,20,30,40,50,60,70,80,90,100]
    }),

    mounted () {
        this.getSettings()
    },

    methods: {
        async getSettings () {
          this.isLoading = true

          const response = await this.$api.get('api/users/setting')
              .catch(error => {
                  this.isLoading = false
                  const str = error.response.data ? error.response.data.detail : ''
                  this.$swal.error('Erreur', str)
              })

          if (response) {
              this.isLoading = false
              this.ghost = response.data
          }
        },

        async save () {
          this.isLoading = true

          let data = new FormData()
          data.append('search_console_nb_result', this.ghost.search_console_nb_result)
          data.append('kwfinder_location', this.ghost.kwfinder_location)
          data.append('search_console_location', this.ghost.search_console_location)

          const response = await this.$api.post('api/users/update/setting', data)
              .catch(error => {
                  this.isLoading = false
                  const str = error.response.data ? error.response.data.detail : ''
                  this.$swal.error('Erreur', str)
              })

          if (response) {
              this.isLoading = false
              this.$swal.success('Confirmation', 'Paramètres modifiés avec succès.')
              this.getSettings()
          }
        }
    }
}
</script>
