<template lang="html">
    <section class="signin-page">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 mt-20 text-center">
                    <div class="alert alert-info alert-dismissible fade show" role="alert">
                        <i class="feather icon-info mr-10"></i>
                        Le mot de passe doit contenir au moins, une majuscule,
                        une miniscule, un caractère spécial, un chiffre et être différent du nom et prénom !
                    </div>
                </div>

                <div class="col-sm-12 mt-20" v-if="message !== ''">
                    <div :class="`alert alert-${alert} alert-dismissible fade show`" role="alert">
                        <i class="feather icon-info mr-10"></i>
                        {{ message }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

                <div class="col-sm-4"></div>

                <div class="col-sm-4" v-show="!isLoading">
                    <div class="login-form">
                        <div class="logo-name text-center">Analytics</div>
                        <div class="title text-center">Changer votre mot de passe</div>
                        <small class="text-center">Le mot de passe doit contenir au moins, une majuscule, une miniscule, un caractère spécial, un chiffre et être différent du nom et prénom !</small>

                         <form class="_form mt-20" @submit.prevent>
                             <div class="form-group">
                                 <div class="inner-addon left-addon">
                                     <i class="glyphicon feather icon-lock"></i>
                                     <input type="password"
                                         name="password"
                                         placeholder="Nouveau mot de passe"
                                         v-model="ghost.password"
                                         :disabled="isLoading"
                                         class="form-control form-control-lg input-white"
                                         required
                                     >
                                 </div>
                             </div>

                             <div class="form-group">
                                 <div class="inner-addon left-addon">
                                     <i class="glyphicon feather icon-lock"></i>
                                     <input type="password"
                                         name="confirm_password"
                                         placeholder="Confirmer nouveau mot de passe"
                                         v-model="ghost.confirm_password"
                                         :disabled="isLoading"
                                         class="form-control form-control-lg input-white"
                                         required
                                     >
                                 </div>
                             </div>

                            <div class="mt-20">
                                <button
                                    class="btn btn-primary btn-block btn-lg"
                                    @click="reset()"
                                    :disabled="isLoading"
                                >
                                    <i class="feather icon-log-in pull-right"></i>
                                    {{ isLoading ? 'En cours...' : 'Changer votre mot de passe'}}
                                </button>
                            </div>
                       </form>
                    </div>
                </div>

                <Loaders v-show="isLoading"></Loaders>

                <div class="col-sm-4"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ConfirmResetPassword',

    computed: {
        user_token () {
            return this.$router.currentRoute._value.params.id
        },

        alert () {
            return this.$store.state.alert
        },

        message () {
            return this.$store.state.message
        },
    },

    mounted () {
        this.checkLink()
    },

    methods: {
        /**
         * Check If Link is Valid
         */
         async checkLink () {
             this.isLoading = true

             let payload = {
                 'token': this.user_token
             }

             const res = await this.$api.get('/valid/link', {params: payload})
                 .catch(error => {
                     this.isLoading = false
                     const str = error.response.data ? error.response.data.detail : ''
                     this.$store.commit('SET_MESSAGE', str)
                     this.$store.commit('SET_ALERT', 'danger')
                     this.go('Signin')
                 })

             this.isLoading = false

             if (res) {
                 this.isLoading = false
                 this.$store.commit('SET_MESSAGE', res.data)
                 this.$store.commit('SET_ALERT', 'success')
             }
         },

        /**
         * SIGNIN
         */
        async reset () {
            if (this.ghost.password !== this.ghost.confirm_password) {
                this.$swal.error('Validation mot de passe', 'Le mot de passe saisi ne correspond pas à la confirmation')
            } else {
                this.isLoading = true

                let formData = new FormData()
                formData.append('token', this.user_token)
                formData.append('password', this.ghost.password)
                formData.append('confirm_password', this.ghost.confirm_password)

                const res = await this.$api.post('/confirm/password/reset', formData)
                    .catch(error => {
                        const str = error.response.data ? error.response.data.detail : ''
                        this.$swal.error('Erreur de connexion', str)
                    })

                this.isLoading = false

                if (res) {
                    this.$store.commit('SET_MESSAGE', res.data)
                    this.$store.commit('SET_ALERT', 'success')
                    this.go('Signin')
                }
            }
        }
    }
}
</script>
