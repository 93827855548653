<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="editUserModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Editer utilisateur</h4>

                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="last_name"
                                    placeholder="Nom de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.last_name"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <input type="text"
                                    name="first_name"
                                    placeholder="Prénom de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.first_name"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <input type="email"
                                    name="email"
                                    placeholder="Email de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.email"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <select
                                    class="form-control form-control-lg"
                                    name="role"
                                    v-model="ghost.role"
                                    required
                                >
                                    <option
                                        v-for="r in roles"
                                        :key="r.uuid"
                                        :value="r.uuid"
                                    >{{ r.name }}</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="close()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'editUserModal',
    emits: ['confirmed'],
    props: {
        user: { type: Object, default: () => {} },
        roles: { type: Array, default: () => [] },
    },

    watch: {
        user: {
            immediate: true,
            handler: function (val) {
                if (!_.isEmpty(val)) {
                    this.ghost = {
                        role: val.role ? val.role.uuid : null,
                        last_name: val.last_name,
                        first_name: val.first_name,
                        email: val.email,
                        uuid: val.uuid
                    }
                }
            }
        }
    },

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            this.ghost = {
                role: this.user.role ? this.user.role.uuid : null,
                last_name: this.user.last_name,
                first_name: this.user.first_name,
                email: this.user.email,
                uuid: this.user.uuid
            }
            window.$('.modal').modal('hide')
        },
    },

}
</script>
