<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addUserModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Nouvel utilisateur</h4>


                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <input type="text"
                                    name="last_name"
                                    placeholder="Nom de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.last_name"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <input type="text"
                                    name="first_name"
                                    placeholder="Prénom de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.first_name"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <input type="email"
                                    name="email"
                                    placeholder="Email de l'utilisateur"
                                    class="form-control form-control-lg"
                                    v-model="ghost.email"
                                    maxlength="50"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <select
                                    class="form-control form-control-lg"
                                    name="role"
                                    v-model="ghost.role"
                                    required
                                >
                                    <option value="">Sélectionner un rôle</option>
                                    <option
                                        v-for="r in roles"
                                        :key="r.uuid"
                                        :value="r.uuid"
                                    >{{ r.name }}</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div class="mt-20">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="close()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'addUserModal',
    emits: ['confirmed', 'cancelled'],
    props: {
        roles: { type: Array, default: () => []}
    },

    mounted () {
        this.$eventBus.$on('addUser', () => {
            this.resetGhost()
        })
    },

    watch: {
        roles: {
            immediate: true,
            handler: function (val) {
                if (val) {
                    this.resetGhost()
                }
            }
        }
    },

    methods: {
        confirm () {
            if (this.ghost.email !== '' && this.ghost.role !== '') {
                this.$emit('confirmed', this.ghost)
                this.close()
            } else {
                return this.$swal.error('Validation', 'Tous les champs du formulaire sont obligatoires')
            }
        },

        close () {
            this.resetGhost()
            window.$('.modal').modal('hide')
        },

        resetGhost () {
            this.ghost = {
                role: '',
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                confirm_password: ''
            }
        },
    },

}
</script>
