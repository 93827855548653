<template lang="html">
      <div :class="'pie-content progress-' + progression">
          <span class="label">{{ progression }}<span class="smaller">%</span></span>
          <div class="pie">
              <div class="left-side half-circle"></div>
              <div class="right-side half-circle"></div>
          </div>
      </div>
</template>

<script>
export default {
    name: 'Progress',

    props: {
        taux: {
            type: Number,
            default: 0
        }
    },

    computed: {
        progression () { return Math.round(this.taux) }
    }
}
</script>
