<template>
    <div class="dashboard">
        <div class="_header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="_header-logo">
                            <i class="feather icon-users"></i>
                            Utilisateurs
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-20">
            <div class="text-right">
                <button
                    class="btn btn-secondary mr-2"
                    @click="cleanUpActions()"
                    :disabled="isLoading"
                    v-if="isSuperAdmin"
                >
                    Nettoyer actions
                </button>

                <button
                    class="btn btn-primary"
                    @click="addUserModal()"
                    :disabled="isLoading"
                >
                    Nouvel utilisateur
                </button>
            </div>


            <div class="izy-table mt-20">
                <div class="izy-table-heading">
                    <div class="row">
                        <div class="col-3">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('last_name')"
                            >
                                Nom
                                <i class="feather icon-chevron-up" v-if="!sort_items.last_name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.last_name"></i>
                            </div>
                        </div>

                        <div class="col-2">
                            <div
                                class="izy-table-th pointer"
                                @click="setSortingKey('first_name')"
                            >
                                Prénom
                                <i class="feather icon-chevron-up" v-if="!sort_items.first_name"></i>
                                <i class="feather icon-chevron-down" v-if="sort_items.first_name"></i>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="izy-table-th">Email</div>
                        </div>

                        <div class="col-2">
                            <div class="izy-table-th">
                                Création
                            </div>
                        </div>

                        <div class="col-2">
                            <div
                                class="izy-table-th"
                            >
                                Rôle

                            </div>
                        </div>
                    </div>
                </div>

                <div class="izy-table-body mt-10">
                    <div class="izy-table-row" v-for="user in users" :key="user.id">
                        <div class="row">
                            <div class="col-3">
                                <div class="izy-table-col">
                                    <i class="feather icon-trash-2 mr-2 pointer" @click="remove(user)"></i>
                                    <span
                                        class="pointer"
                                        @click="openUser(user)"
                                    >
                                        {{ user.last_name }}
                                    </span>
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ user.first_name }}
                                </div>
                            </div>

                            <div class="col-3">
                                <div class="izy-table-col">
                                    {{ user.email }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ created_at(user.created_at) }}
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="izy-table-col">
                                    {{ user.role ? user.role.name : '---' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-20 text-center">
                    <izyPaginate
                        @nextPage="loadUsers"
                        @previousPage="loadUsers"
                        @currentPage="loadUsers"
                        :showNextBtn="showNextBtn"
                        :page="page"
                    ></izyPaginate>
                </div>
            </div>
        </div>

        <Loaders v-show="isLoading"></Loaders>

        <!-- modals of users -->
        <addUserModal :roles="roles" @confirmed="saveUser"></addUserModal>
        <ConfirmModal
            :id="'confirmModal'"
            :title="deleteModalTitle"
            :message="deleteModalDescription"
            :resource="selected"
            @confirmed="confirmRemove" />

        <div></div>
    </div>
</template>

<script>
import _ from 'lodash'
import AuthService from '@/services/auth'
import addUserModal from './modals/add-user-modal'

export default {
    name: 'Users',

    data: () => ({
        page: 1,
        showNextBtn: false,
        selected: {},
        users: [],
        sort_items:{
            'last_name': true,
            'first_name': true,
            'role': true
        },
        src: '',
        deleteModalTitle: 'Confirmation',
        deleteModalDescription: 'Voulez-vous vraiment supprimer cet utilisateur ?'
    }),

    components: { addUserModal },

    computed: {
        roles () {
            return this.$store.state.roles.roles.filter(role => role.id !== 1)
        },

        auth () {
            return AuthService.user()
        },

        isSuperAdmin () {
            return this.auth.account.role.id == 1
        },
        usersList () { return JSON.parse(localStorage.getItem('usersList')) },
        pageNumber () { return localStorage.getItem('pageNumber') }
    },

    mounted () {
        this.$store.dispatch('roles/getRoles')
        this.$nextTick(() => {
            if(!_.isEmpty(this.usersList)) {
                this.users = this.usersList
                this.page = this.pageNumber
            }
            if(_.isEmpty(this.usersList)) { this.loadUsers() }
        })
    },

    methods: {
        /**
        * Set sorting key
        *
        * @return {void}
        */
        async setSortingKey (val) {
            let sort_type = this.sort_items[val] ? "asc": "desc"
            this.sort_items[val] = !this.sort_items[val]

            this.ghost.sort_on = val
            this.ghost.sort_type = sort_type
            this.loadUsers()
        },

        /**
        * Open Add User Modal
        *
        * @return {void}
        */
        addUserModal () {
            this.$eventBus.$emit('addUser')
            window.$('#addUserModal').modal('show')
        },

        /**
        * Open Delete User Modal
        *
        * @return {void}
        */
        remove (user) {
            this.selected = user
            window.$('#confirmModal').modal('show')
        },

        /**
        * Navigate to user logs
        *
        * @return {void}
        */
        openUser (user) {
            this.navigate({
                name: 'User', params: { id: user.uuid }
            })
        },

        /**
        * Load Users List
        *
        * @return {void}
        */
        async loadUsers (page = 1) {
            if (this.src !== 'save') {
                this.isLoading = true
            }
            const payload = {
                "page": page,
                "sort_on": this.ghost.sort_on,
                "sort_type": this.ghost.sort_type,
            }

            const res = await this.$api.get(`api/admin/users/list`, {params: payload})
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.isLoading = false
                this.users = res.data.users.filter(user => user.id != this.auth.account.id)
                localStorage.setItem('usersList', JSON.stringify(this.users))
                this.page = page
                this.src = ''
                this.showNextBtn = res.data.more
                localStorage.setItem('pageNumber', this.page)
            }
        },

        /**
        * Save User
        *
        * @return {void}
        */
        async saveUser (user) {
            this.isLoading = true

            let formData = new FormData()
            formData.append('role', user.role)
            formData.append('email', user.email)
            formData.append('last_name', user.last_name)
            formData.append('first_name', user.first_name)
            formData.append('password', user.password)

            const res = await this.$api.post(`api/admin/users/store`, formData)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Vous allez recevoir très prochainement un lien pour créer votre mot de passe')
                this.loadUsers(this.page)
            }
        },

        /**
        * Delete User
        *
        * @return {void}
        */
        async confirmRemove (user) {
            this.isLoading = true

            const res = await this.$api.delete(`/api/admin/users/${user.uuid}/delete`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.src = 'save'
                this.$swal.success('Confirmation', 'Suppression effectuée avec succès')
                this.loadUsers(this.page)
            }
        },

        /**
        * Clean Up Actions
        *
        * @return {void}
        */
        async cleanUpActions () {
            this.isLoading = true

            const res = await this.$api.post(`api/users/actions/cleanup`)
                .catch(error => {
                    this.isLoading = false
                    const str = error.response.data ? error.response.data.detail : ''
                    this.$swal.error('Erreur', str)
                })

            if (res) {
                this.$swal.success('Confirmation', res.data)
                this.loadUsers()
            }
        },
    }
}
</script>
