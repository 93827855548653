<template lang="html">
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="brand">
                <a @click.prevent="navigate({ name: 'Home' })">
                    Analytics
                </a>
            </li>

            <li>
                <router-link :to="{ name: 'Home' }">
                    <i class="feather icon-home"></i>
                   Tableau de bord
                </router-link>
            </li>

            <li>
                <a
                    @click="openClients()"
                    :class="[route.path.includes('/clients') ? 'active' : '']"
                >
                    <i class="feather icon-share-2"></i>
                    Clients
                </a>
            </li>

            <li>
                <a
                    @click="openCorpus()"
                    :class="[route.path.includes('/corpus') ? 'active' : '']"
                >
                    <i class="feather icon-paperclip"></i>
                    Corpus
                </a>
            </li>

            <li>
                <a
                    @click="openProjects()"
                    :class="[route.path.includes('/projects') ? 'active' : '']"
                >
                    <i class="feather icon-folder"></i>
                    Projets
                </a>
            </li>

            <li v-if="role==1 || role==2">
                <a
                    @click="openUsers()"
                    :class="[route.path.includes('/users') ? 'active' : '']"
                >
                    <i class="feather icon-users"></i>
                    Utilisateurs
                </a>
            </li>

            <li>
                <a
                    @click="openSettings()"
                    :class="[route.path.includes('/settings') ? 'active' : '']"
                >
                    <i class="feather icon-settings"></i>
                    Paramètres
                </a>
            </li>

            <li>
                <a
                    @click="openAccount()"
                    :class="[route.path.includes('/account') ? 'active' : '']"
                >
                    <i class="feather icon-user"></i>
                    Mon compte
                </a>
            </li>

            <li>
                <a @click.prevent="signout()">
                    <i class="feather icon-log-out"></i>
                    Déconnexion
                </a>
            </li>
        </ul>

    </div>
</template>

<script>
export default {
    name: 'MainSidebar',

    data: () => ({
        role: 1
    }),

    computed: {
        route () { return this.$route },
        corpusList () { return JSON.parse(localStorage.getItem('corpusList')) },
        usersList () { return JSON.parse(localStorage.getItem('usersList')) },
        projectsList () { return JSON.parse(localStorage.getItem('projectsList')) },
    },

    mounted () {
        this.role = localStorage.getItem('roleCode')
    },

    methods: {
        openCorpus () {
            this.removeLocalStorage()
            this.go('Corpus')
        },

        openClients () {
            this.removeLocalStorage()
            this.go('Clients')
        },

        openUsers () {
            this.removeLocalStorage()
            this.go('Users')
        },

        openSettings () {
            this.removeLocalStorage()
            this.go('Settings')
        },

        openAccount () {
            this.removeLocalStorage()
            this.go('Account')
        },

        removeLocalStorage () {
            if (this.corpusList !== null || this.corpusList !== undefined) { localStorage.removeItem('corpusList') }
            if (this.usersList !== null || this.usersList !== undefined) { localStorage.removeItem('usersList') }
            if (this.projectsList !== null || this.projectsList !== undefined) { localStorage.removeItem('projectsList') }
        },

        openProjects () {
            this.removeLocalStorage()
            this.go('Projects')
        },

        signout () {
            this.$auth.logout()
            window.location.reload()
        }
    }
}
</script>
