<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="passwordModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Réinitialisation du mot de passe</h4>

                    <form class="_form mt-40" @submit.prevent>
                        <div class="form-group" v-if="showOld">
                            <input type="password"
                                name="current_password"
                                placeholder="Mot de passe actuel"
                                class="form-control form-control-lg"
                                :disabled="isLoading"
                                required
                                v-model="ghost.current_password"
                            >
                        </div>

                        <div class="form-group">
                            <input type="password"
                                name="password"
                                placeholder="Nouveau mot de passe"
                                class="form-control form-control-lg"
                                :disabled="isLoading"
                                required
                                v-model="ghost.password"
                            >
                        </div>

                        <div class="form-group">
                            <input type="password"
                                name="confirm_password"
                                placeholder="Confirmer le mot de passe"
                                class="form-control form-control-lg"
                                :disabled="isLoading"
                                required
                                v-model="ghost.confirm_password"
                            >
                        </div>
                    </form>

                    <div class="mt-40 pb-20">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'PasswordModal',
    emits: ['confirmed', 'cancelled'],
    props: { showOld: { type: Boolean, default: false }},

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            this.ghost = {
                current_password: '',
                password: '',
                confirm_password: ''
            }

            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        }
    }
}
</script>
