<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="selectEtiquetteModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content _etiq">
                <div class="modal-body">
                    <div class="form-container">
                        <form class="_form mt-20" @submit.prevent>
                            <div class="form-group">
                                <VueMultiselect
                                    v-model="value"
                                    :options="tagsList"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :multiple="true"
                                    placeholder="Sélectionnez les étiquettes"
                                />
                            </div>
                            <div class="can-create-tag">
                                <a @click="add()">Créer</a>
                            </div>
                        </form>
                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-12">
                                <button
                                    class="btn btn-lg btn-primary btn-block"
                                    @click="confirm()"
                                >{{ 'Valider' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'

export default {
    name: 'selectEtiquetteModal',
    emits: ['confirmed', 'cancelled', 'add'],
    components: { VueMultiselect },

    data: () => ({
        value: null
    }),

    props: {
        tagsList: {
            type: Array,
            default: () => []
        },

        keyword: {
            type: Object,
            default: () => {}
        },

        page: {
            type: Number,
            default: 1
        },
    },

    watch: {
        tagsList (val) {
            return _.cloneDeep(val)
        }
    },

    mounted () {
        this.$eventBus.$on('selectKeywordTags', (result) => {
            if (!_.isEmpty(result)) {
                this.value = result.map(t => t.tag)
            } else {
                this.value = []
            }
        })
    },

    methods: {
        /**
         * keyword Tags changed
         *
         * @param  {[type]}  payload
         * @return {Promise}
         */
        async tagsChanged (payload) {
            if (payload.action === 'add') {
                return this.selectedTags.push(payload.value)
            }

            this.selectedTags = this.selectedTags.filter(s => s !== payload.value)
        },

        add () {
            this.$emit('add', this.keyword)
            this.close()
        },

        confirm () {
            this.ghost.keyword = this.keyword.uuid
            this.ghost.tags = this.value
            this.ghost.type = 'many'
            this.ghost.page = this.page
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            this.selectedTags = []
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        }
    }
}
</script>
