/* eslint no-unused-vars: "off" */
import ApiService from '@/services/api'

export default {
    namespaced: true,

    state: {
        roles: []
    },

    mutations: {
        SET_ROLES (state, payload) {
            state.roles = payload
        }
    },

    actions: {
        async getRoles ({ commit }) {
            const response = await ApiService.get('roles/list')
                .catch(error => console.log(error))

            commit('SET_ROLES', response.data)
        }
    },

    getters: {
        getRoles: state => state.roles
    }
}
