<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        :id="id"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <h2 class="modal-confirm">{{ title }}</h2>
                    <h4 class="modal-message">{{ message }}</h4>

                    <div class="alert alert-success alert-dismissible fade show mt-20" role="alert" v-if="showAlert">
                        <i class="feather icon-alert-triangle mr-10"></i>
                        Les projets qui utilisent déjà ce corpus ne seront pas touchés par cette modification.

                        <!-- <button type="button" class="close mb-20" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>

                    <div class="mt-40">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ confirmBtnText }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ cancelBtnText }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmModal',
    emits: ['confirmed', 'cancelled'],

    props: {
        id: { type: String, default: 'confirmModal' },
        title: { type: String, default: 'Etes-vous sûr de vouloir continuer ?' },
        message: { type: String, default: '...' },
        resource: { type: Object, default: () => {} },
        page: { type: Number, default: 0 },
        confirmBtnText: { type: String, default: 'Oui' },
        cancelBtnText: { type: String, default: 'Non' },
        showAlert: { type: Boolean, default: false },
    },

    methods: {
        confirm () {
            if (this.page > 0) {
                localStorage.setItem('current_page', JSON.stringify(this.page))
            }

            if (this.resource) {
                this.$emit('confirmed', this.resource)
            }

            this.close()
        },

        close () {
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        }
    }
}
</script>
