<template lang="html">
    <div class="row no-gutters">
        <div class="col-sm-4">
            <select class="form-control input-white" name="" readonly>
                <option :value="item.value">{{ displayLabel(item) }}</option>
            </select>
        </div>
        <div class="col-sm-3">
            <input type="text" name="min" :value="item.min" v-if="!isEdit" readonly class="form-control input-white">
            <input type="text" name="min" v-model="ghost.min" class="form-control input-white" v-else>
        </div>

        <div class="col-sm-3">
            <input type="text" name="max" :value="item.max" v-if="!isEdit" readonly class="form-control input-white">
            <input type="text" name="max" v-model="ghost.max" v-else class="form-control input-white">
        </div>

        <div class="col-sm-2">
            <div class="row no-gutters pointer">
                <div class="col-sm-6" style="padding-top: 5px; font-size:18px">
                    <div class="text-center" @click="edit()" v-if="!isEdit">
                        <i class="feather icon-edit-2"></i>
                    </div>

                    <div class="text-center" @click="save()" v-else>
                        <i class="feather icon-save"></i>
                    </div>
                </div>

                <div class="col-sm-6" style="padding-top: 5px; font-size:18px">
                    <div class="text-center" @click="del()">
                        <i class="feather icon-x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'FilterLine',

    props: { item: { type: Object, default: () => {} } },

    data: () => ({
        isEdit: false
    }),

    mounted () {
        this.ghost = Object.assign(this.item, {})
    },

    watch: {
        item (val) {
            if (!_.isEmpty(val)) {
                this.ghost = Object.assign(this.item, {})
            }
        },
    },
    methods: {
        del () {
            this.$emit('deleted', this.item)
        },

        edit () {
            const _shit = this
            _shit.isEdit = !_shit.isEdit
            _shit.ghost.min = _shit.item.min
            _shit.ghost.max = _shit.item.max
        },

        save () {
            const _shit = this
            _shit.isEdit = !_shit.isEdit
            this.$emit('edited', _shit.ghost)
        },

        displayLabel (item) {
          let lbl = ""
          if (item.value == "Longueur") lbl = "Longueur"
          else if (item.value == "Occurences") lbl = "Occurences"
          else if (item.value == "Verbatims") lbl = "#Verbatims"
          else if (item.value == "Volume") lbl = "Volume"
          else if (item.value == "Score") lbl = "Score"
          else if (item.value == "Tags") lbl = "#Etiquettes"

          return lbl
        }
    },
}
</script>
