<template lang="html">
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="brand">
                <a @click.prevent="openHome()">
                    Analytics
                </a>
            </li>

            <div class="mt-20"></div>

            <li>
                <a @click.prevent="openProject()" :class="isProjectRoute ? 'active' : ''">
                    <i class="feather icon-home" v-show="!isProjectRoute"></i>
                    Home
                    <i class="feather icon-chevron-right" v-show="isProjectRoute"></i>
                </a>
            </li>

            <li>
                <a @click.prevent="openVerbatims()" :class="isVerbatimsRoute ? 'active' : ''">
                    <i class="feather icon-chevron-left" v-show="!isVerbatimsRoute"></i>
                    Verbatims
                    <i class="feather icon-chevron-right" v-show="isVerbatimsRoute"></i>
                </a>
            </li>

            <li>
                <a @click.prevent="openKeywords()" :class="isMotsClesRoute ? 'active' : ''">
                    <i class="feather icon-chevron-left" v-show="!isMotsClesRoute"></i>
                    Mots clés
                    <i class="feather icon-chevron-right" v-show="isMotsClesRoute"></i>
                </a>
            </li>

            <li>
                <a @click.prevent="openLexiques()" :class="isLexiquesRoute ? 'active' : ''">
                    <i class="feather icon-chevron-left" v-show="!isLexiquesRoute"></i>
                    Lexiques
                    <i class="feather icon-chevron-right" v-show="isLexiquesRoute"></i>
                </a>
            </li>
        </ul>

        <ul class="sidebar-nav bottom">

        </ul>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',

    props: {
        menus: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        isProjectRoute () {
            return this.$router.currentRoute._value.name === 'Project'
        },

        isVerbatimsRoute () {
            return this.$router.currentRoute._value.name === 'Verbatims'
        },

        isMotsClesRoute () {
            return this.$router.currentRoute._value.name === 'Keywords'
        },

        isLexiquesRoute () {
            return this.$router.currentRoute._value.name === 'Lexiques'
        },
    },

    methods: {
        signout () {
            this.$auth.logout()
        },

        openProject () {
            let id = this.$router.currentRoute._value.params.id
            this.navigate({ name: 'Project', params: { id } })
        },

        openVerbatims () {
            let id = this.$router.currentRoute._value.params.id
            this.navigate({ name: 'Verbatims', params: { id } })
        },

        openKeywords () {
            let id = this.$router.currentRoute._value.params.id
            this.navigate({ name: 'Keywords', params: { id } })
        },

        openLexiques () {
            let id = this.$router.currentRoute._value.params.id
            this.navigate({ name: 'Lexiques', params: { id } })
        },

        openHome () {
            this.navigate({ name: 'Home' })
        },
    }
}
</script>
