<template lang="html">
    <div class="_lds">
        <div class="lds-spinner">
            <div></div><div></div>
            <div></div><div></div>
            <div></div><div></div>
            <div></div><div></div>
            <div></div><div></div>
            <div></div><div></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loaders',
    }
</script>
