<template>
    <div
        class="modal fade"
        tabindex="-1" role="dialog"
        aria-hidden="true"
        id="addCorpusModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="modal-confirm text-center">Nouveau corpus</h4>

                    <form class="_form mt-40" @submit.prevent>
                        <div class="form-group">
                            <input type="text"
                                name="name"
                                placeholder="Nom du corpus"
                                class="form-control form-control-lg"
                                :disabled="isLoading"
                                required
                                v-model="ghost.name"
                            >
                        </div>

                        <div class="form-group">
                            <select
                                class="form-control form-control-lg"
                                name="client"
                                v-model="ghost.client"
                                required
                            >
                                <option value="">Sélectionner un client</option>
                                <option
                                    v-for="c in clients"
                                    :key="c.uuid"
                                    :value="c.uuid"
                                >{{ c.name }}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <textarea
                                name="description" rows="5"
                                class="form-control"
                                placeholder="Description du corpus"
                                v-model="ghost.description"
                            ></textarea>
                        </div>
                    </form>

                    <div class="mt-40 pb-20">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-primary"
                                    @click="confirm()"
                                >{{ 'Enregistrer' }}</button>
                            </div>

                            <div class="col-6">
                                <button
                                    class="btn btn-lg btn-block btn-outline-secondary"
                                    @click="cancel()"
                                >{{ 'Annuler' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import _ from 'lodash'

export default {
    name: 'AddCorpusModal',
    emits: ['confirmed', 'cancelled'],

    data: () => ({
        clients: []
    }),

    mounted () {
        this.$eventBus.$on('addCorpus', (result) => {
            if (!_.isEmpty(result)) {
                this.resetGhost()
                this.clients = result
            }
        })
    },

    methods: {
        confirm () {
            this.$emit('confirmed', this.ghost)
            this.close()
        },

        close () {
            this.resetGhost()
            window.$('.modal').modal('hide')
        },

        cancel () {
            this.$emit('cancelled')
            this.close()
        },

        resetGhost () {
            this.ghost = {
                name: '',
                client: '',
                description: '',
            }
        },
    }
}
</script>
