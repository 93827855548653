<template>
    <div id="app">
        <div id="wrapper">
            <MainSidebar  v-if="token" />


            <div id="page-content-wrapper">
                <router-view />

                <div class="text-right mt-10">

                </div>
            </div>

            <div class="card _bottom-card" v-if="showModal">
                <div class="card-header">
                    <div class="_name">Progression</div>
                    <div class="_close" @click="closeTimer()">
                        <i class="feather icon-x"></i>
                    </div>
                </div>

                <!-- Uninstalling -->
                <div class="card-body text-center">
                    <div class="set-size charts-container">
                      <div :class="'pie-wrapper progress-' + progression">
                        <span class="label">{{ progression }}<span class="smaller">%</span></span>
                        <div class="pie">
                          <div class="left-side half-circle"></div>
                          <div class="right-side half-circle"></div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import AuthService from '@/services/auth'
import ApiService from '@/services/api'
import moment from 'moment'

export default {
    name: 'App',

    data: () => ({
        duration: '',
        endDate: '',
        intervalIndex: null,
        interval: null,
    }),

    created () {
        this.$store.dispatch('roles/getRoles')
        this.setAuth()
    },

    mounted () {
        window.eventBus.$on('task', (result) => {
            let task_uuid = JSON.parse(localStorage.getItem('task_uuid'))
            this.$store.commit('SET_SHOW_MODAL', true)
            this.$store.commit('SET_PROCESSING', true)
            if (result == 'state') {
                this.intervalIndex = setInterval(() => {
                    this.$store.dispatch('projects/getTaskStatus', task_uuid)
                }, 10000)
            }
        })

        if (!_.isEmpty(this.task_uuid)) {
            let task_uuid = JSON.parse(localStorage.getItem('task_uuid'))
            this.$store.commit('SET_SHOW_MODAL', true)
            this.$store.commit('SET_PROCESSING', true)
            this.intervalIndex = setInterval(() => {
                this.$store.dispatch('projects/getTaskStatus', task_uuid)
            }, 10000)
        }
    },

    watch: {
         progression: {
             immediate: true,
             handler: function (value) {
                 if ((value > 99) && (value <= 100)) {
                     this.endDate = moment().add(10, 's')
                     this.makeTimer()
                 }
             }
         },

         duration (val) {
             if (val <= 0) {
                 this.closeTimer()
                 return true
             }
         }
     },

    computed: {
        token () {
            const user = _.cloneDeep(this.$store.state.user) || {}
            return user.token
        },

        auth () {
            return AuthService.user()
        },

        task_uuid () {
            return JSON.parse(localStorage.getItem('task_uuid'))
        },

        showModal () {
            return this.$store.state.showModal
        },

        progression () {
            let taux =  Math.round(this.$store.state.projects.progression)
            if (taux == 100) {
                this.downloadPosFile()
            }
            return taux
        },

        processing () {
            return this.$store.state.processing
        },
    },

    methods: {
        setAuth () {
            const token = AuthService.getToken()
            const user = AuthService.getAuth()

            if (token) {
                ApiService.setToken(token)
                this.$store.commit('SET_USER', user)
            }
        },

        closeTimer () {
            clearInterval(this.interval)
            clearInterval(this.intervalIndex)
            this.$store.commit('SET_SHOW_MODAL', false)
            this.$store.commit('projects/SET_PROGRESSION', 0)
            this.duration  = '00:00:00'
            this.duration  = ''
        },

        makeTimer () {
            this.interval = setInterval(() => {
                let duration = moment.duration(this.endDate.diff(moment(), true))
                let seconds = duration.get('seconds') > 9
                    ? duration.get('seconds')
                    : '0' + duration.get('seconds')

                this.duration = seconds
            }, 1000)
        },

        downloadPosFile () {
            setTimeout(() => {
                this.loaD()
            }, 10000)
        },

        async loaD () {
          this.isLoading = true
          let lexique = JSON.parse(localStorage.getItem('lexique'))
          let get_payload = {
              temp_file: lexique.uuid
          }

          let url = `api/lexicons/${lexique.uuid}/project/position/export`
          const res = await this.$api.get(url, { params:get_payload, responseType:'blob' } )
              .catch(error => {
                  this.isLoading = false
                  const str = error.response.data ? error.response.data.detail : ''
                  this.$swal.error('Erreur stats export', str)
              })
          if (res) {
              this.isLoading = false
              const Url = window.URL.createObjectURL(new Blob([res.data]))
              const link = document.createElement('a')
              link.href = Url
              link.setAttribute('download', "lexique_project_stats_positions_" + lexique.name + ".xlsx")
              document.body.appendChild(link)
              link.click()
              localStorage.removeItem('lexique')
              localStorage.removeItem('task_uuid')
              this.$store.commit('SET_PROCESSING', false)
              // document.location.reload(true)
          }
        }
    }
}
</script>

<style lang="scss">
@import "./assets/sass/app";
</style>
