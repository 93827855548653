/* eslint no-unused-vars: "off" */
import ApiService from '@/services/api'

export default {
    namespaced: true,

    state: {
        progression: 0,
        taux: {},
        task: {},
        ended: false,
        next_projects: null,
        project: {},
        projects: [],
        project_corpus: [],
        project_infos: [],
        names: [],
    },

    mutations: {
        SET_TASK (state, payload) {
            state.task = payload
        },

        SET_PROGRESSION (state, value) {
            state.progression = value
        },

        SET_TAUX (state, payload) {
            state.taux = payload
        },

        ADD_PROJECT (state, payload) {
            state.projects.push(payload)
        },

        SET_NAMES (state, payload) {
            state.names = payload
        },

        SET_PROJECTS (state, payload) {
            state.projects = payload
        },

        SET_PROJECT_FILES (state, payload) {
            state.project_corpus = payload
        },

        SET_PROJECT_INFOS (state, payload) {
            state.project_infos = payload
        },

        SET_PROJECT (state, payload) {
            state.project = payload
        },

        SET_NEXT_PROJECTS (state, next) {
            state.next_projects = next
        },
    },

    actions: {
        async getProjects ({ commit }) {
            const response = await ApiService.get('api/projects/list')
                .catch(error => console.log(error))

            commit('SET_PROJECTS', response.data.projects)
            commit('SET_NEXT_PROJECTS', response.data.next)
        },

        async getTaskStatus ({ commit }, task_uuid) {
            const response = await ApiService.get(`api/tasks/${task_uuid}/state`)
                .catch(error => console.log(error))

            commit('SET_TASK', response.data)
            commit('SET_PROGRESSION', response.data.progression)
        },

        async getProjectCorpus ({ commit }, uuid) {
            const response = await ApiService.get(`api/projects/${uuid}/corpus`)
                .catch(error => console.log(error))

            commit('SET_PROJECT_FILES', response.data)
        },

        async getProjectInfos ({ commit }, uuid) {
            const response = await ApiService.get(`api/projects/${uuid}/info`)
                .catch(error => console.log(error))

            commit('SET_PROJECT_INFOS', response.data)
        },

        async getProject ({ commit }, uuid) {
            const response = await ApiService.get(`api/projects/${uuid}/show`)
                .catch(error => console.log(error))

            commit('SET_PROJECT', response.data)
        },

        async getTaux ({ commit }, uuid) {
            const response = await ApiService.get(`api/projects/processing/${uuid}/state`)
                .catch(error => console.log(error))

            if (response.data.progression == 100 && response.data.status == "Complete") {
                window.eventBus.$emit('ProjectAddedComplete')
            }

            commit('SET_TAUX', response.data)
        }
    },

    getters: {
        getProject: state => state.project,
        getProjects: state => state.projects,
        getProjectCorpus: state => state.project_corpus,
        getProjectInfos: state => state.project_infos,
        getTaux: state => state.taux,
        getTaskStatus: state => state.task,
    }
}
